import {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../index";
import axios from "axios";


export const AuthContext = createContext({});
export let instance = axios.create()

export const AuthProvider = ({children}) => {

    const {endpoints} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(undefined);
    const [roles, setRoles] = useState([]);
    const [authInitIsComplete, setAuthInitIsComplete] = useState(false);
    const [axiosIntInitIsComplete, setAxiosIntInitIsComplete] = useState(false);

    //Storage methods
    const useLocalStorage = true;

    const clientThinksItsLoggedIn = () => {
        if (useLocalStorage) {
            return localStorage.getItem("debuggy_isLoggedIn") === "true";
        }
    }

    const setIsLoggedIn = (isLoggedIn) => {
        if (useLocalStorage) {
            localStorage.setItem("debuggy_isLoggedIn", isLoggedIn.toString())
        }
    }


    const saveUserInfo = (user) => {
        //LocalStorage
        if (useLocalStorage) {
            localStorage.setItem("debuggy_user", JSON.stringify(user))
        }
    }

    const getUserInfo = () => {
        //LocalStorage
        if (useLocalStorage) {
            return JSON.parse(localStorage.getItem( "debuggy_user"));
        }
    }

    const updateUser = (user) => {
        setUser((prevUser) => {
            var updatedUser = {
                ...prevUser
            }
            if (user.displayName !== undefined && user.displayName !== null){
                updatedUser.displayName = user.displayName;
            }
            if (user.username !== undefined && user.username !== null){
                updatedUser.username = user.username;
            }
            return updatedUser
        })
    }

    const getUser = () => {
        return user;
    }

    //Save tokens
    const saveAuth = (access_token, refresh_token) => {

        //LocalStorage
        if (useLocalStorage) {
            localStorage.setItem("debuggy_access_token", access_token)
            localStorage.setItem("debuggy_refresh_token", refresh_token)
        }

    }

    //Retrieve tokens
    const retrieveAuth = () => {
        var access_token = "";
        var refresh_token = "";

        //LocalStorage
        if (useLocalStorage) {
            access_token = localStorage.getItem("debuggy_access_token");
            refresh_token = localStorage.getItem("debuggy_refresh_token");
        }

        return [access_token, refresh_token];
    }

    const getAccessTokenHeader = () => {
      const [access_token, refresh_token] = retrieveAuth();
        return {
            Authorization: "Bearer " + access_token
        }
    }

    const logOut = () => {
      setUser(undefined);
      setIsLoggedIn(false);
      saveAuth("","");
      navigate("/login");
    }

    const logIn = async (from, email, password) => {

        // console.log("Logging in user " + email)
        const params = new URLSearchParams();
        params.append('username', email);
        params.append('password',password);
        try {
            const response = await instance.post(`${endpoints.login}`,params)
            // console.log("Successfully logged in!")
            // console.log(JSON.stringify(response?.data))
            const access_token = response?.data?.access_token;
            const refresh_token = response?.data?.refresh_token;
            const user = JSON.parse(response?.data?.user);

            // const getUserResponse = await instance.get(`${endpoints.users}/${user.userId}`)
            // const userInfo = JSON.parse(getUserResponse?.data?.user);
            // setUser(userInfo);
            // saveUserInfo(userInfo);

            setUser(user);
            saveUserInfo(user);
            setIsLoggedIn(true);
            saveAuth(access_token, refresh_token);
            navigate(from, { replace: true });

        } catch (err) {
            console.error(`Error: ${err}`)
            if (!err?.response){
                return "No Server Response";
            } else if (err.response?.status === 400){
                return "Missing Username or Password"
            } else if (err.response?.status === 401){
                return "Unauthorized"
            } else if (err.code === "ERR_NETWORK") {
                return "Network error, please try again later"
            } else {
                return "Unknown error, please try again later"
            }
            // errRef.current.focus();
        }

    }

    const refreshAuthToken = async () => {
        const [access_token, refresh_token] = retrieveAuth();
        const response = await instance.post(endpoints.refresh, {},{
            _refresh: true,
            headers: {
                "Authorization": "Bearer " + refresh_token,
            }
        })
        saveAuth(response?.data?.access_token,response?.data?.refresh_token);
    }

    const redirect401 = () => {
        logOut()
    }

    const redirect403 = () => {
        logOut()
    }


    //AXIOS INTERCEPTORS
    useEffect(() => {

        //RESPONSE
        const resInterceptor = (res) => {
            return res;
        }
        const resErrInterceptor = async (err) => {
            const originalConfig = err.config;
            if (originalConfig.url !== `${endpoints.login}`
                && originalConfig.url !== `${endpoints.register}`
                && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const [access_token, refresh_token] = retrieveAuth();
                        const rs = await instance.get(`${endpoints.refresh}`, {
                            headers: {
                                Authorization: "Bearer " + refresh_token
                            },
                            _refresh: true,
                        });
                        const newAccessToken = rs?.data?.access_token;
                        saveAuth(newAccessToken,refresh_token);
                        return instance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                } else if (err.response.status === 403 && originalConfig._retry){
                    redirect403()
                }
            }
            return Promise.reject(err);
        }
        const responseInterceptor = instance.interceptors.response.use(resInterceptor, resErrInterceptor);


        //REQUEST
        const reqInterceptor = config => {
            const [access_token, refresh_token] = retrieveAuth();

            if (!config?._refresh) {
                if (access_token && access_token !== '') {
                    config.headers.Authorization = "Bearer " + access_token;
                }
            }
            return config;

            // if ( isLoggedIn() && !config._refresh){
            //     if (access_token && access_token !== ''){
            //         console.log("Adding access_token: " + access_token)
            //         config.headers.Authorization = "Bearer " + access_token;
            //     }
            // }
            // return config;
        }
        const reqErrInterceptor = (error) => {
            return Promise.reject(error)
        }
        const requestInterceptor = instance.interceptors.request.use(reqInterceptor,reqErrInterceptor);

        setAxiosIntInitIsComplete(true);

        return () => {
            instance.interceptors.response.eject(responseInterceptor);
            instance.interceptors.request.eject(requestInterceptor);
        }

    }, []);

    //Logged In Init
    useEffect(() => {
        const init = async () => {
            let success = false;
            let userInfo = null;
            try {
                userInfo = getUserInfo();
                const [access_token, refresh_token] = retrieveAuth();
                const loggedIn = clientThinksItsLoggedIn()
                let response = null

                if ((access_token && access_token !== "" ) &&
                    (refresh_token && refresh_token !== "" )){
                    response = await instance.get(`${endpoints.active}`)
                }

                // console.log(response);


                if (!userInfo || !access_token || !refresh_token || !loggedIn ||
                    ( !access_token || !refresh_token || response?.status !== 200)
                ){
                    success = false
                } else {
                    success = true
                    setUser(userInfo);
                }
            } catch (e) {
                // console.log("Auth Init failed, logging out")
            }

            if (!success){
                setUser(null);
                saveUserInfo("")
                setIsLoggedIn(false);
                saveAuth("","");
            } else {
                setUser(userInfo);
            }
            setAuthInitIsComplete(true)
        }

        init()


    }, []);



    const auth = {
        auth: {
            saveAuth,
            retrieveAuth,
            refreshAuthToken,
            getAccessTokenHeader,
            updateUser,
            getUser,
            user,
            setUser,
            // roles, setRoles,
            isLoggedIn: clientThinksItsLoggedIn,
            logIn, logOut,
            redirect401, redirect403
        }
    }

    return (
        authInitIsComplete && axiosIntInitIsComplete && (
            <AuthContext.Provider value={auth}>
                {children}
            </AuthContext.Provider>
        )

    )
}
