import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import NotesIcon from '@mui/icons-material/Notes';
import {ErrorBoundary} from "../../../ErrorBoundary";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../../index";
import React, {useContext, useState} from "react";
import {ContentCopy} from "@mui/icons-material";
import {instance} from "../../../providers/AuthProvider";


export default function IssueDetails(props) {

    const location = useLocation()
    const navigate = useNavigate();

    const {endpoints} = useContext(AppContext)

    const onChangeName = (e) => {
        props.setEditSomethingChanged(true)
        props.setNewName(e.target.value)
    }
    const onChangeDescription = (e) => {
        props.setEditSomethingChanged(true)
        props.setNewDescription(e.target.value)
    }

    const handleSaveChanges = (e) => {
        e.preventDefault();
        //console.log(props.issue)
        const updateIssue = {
            ...props.issue,
            issueName: props.newName,
            issueDescription: props.newDescription,
        };
        props.updateIssue(updateIssue).then(
            props.toggleEditing(true)
        );

    }


    const [linkCopied, setLinkCopied] = useState(false);

    const handleCopyIssueLink = () => {
        navigator.clipboard.writeText(`${endpoints.websiteUrl}${location.pathname}`)
        setLinkCopied(true)
    }

    const handleCopyIssueLinkMouseLeave = () => {
        setLinkCopied(false)
    }

    const [duplicateIssueDialogIsOpen, setDuplicateIssueDialogIsOpen] = useState(false);

    function duplicateIssue() {
        setDuplicateIssueDialogIsOpen(false)
        //Make api call
        instance.post(`${endpoints.issues}/${props.issue.issueId}/duplicate`)
            .then(response => {
                // console.log(response.data);

                // props.setIssue(response.data)
                navigate(`/dashboard/${response.data.issueId}`)
                navigate(0)
            })
            .catch(error => {
                console.error(`Error: ${error}`)
            })

    }

    return (
        <>
            <Container>
                <Grid container direction={"column"}>
                    <ErrorBoundary>
                        <Box sx={{margin: "10px 0 10px"}} className={"issue-buttons"}>
                            <ButtonGroup>
                                <Tooltip id={"edit-issue-button"} title={"Edit Issue"} placement={"top"}>
                                    {/*<span>*/}
                                    <Button variant={"outlined"} disabled={props.isEditing} onClick={props.toggleEditing}
                                            size={"small"}><EditIcon/></Button>
                                    {/*</span>*/}
                                </Tooltip>
                                <Tooltip
                                    id={"duplicate-issue-button"}
                                    title={"Duplicate Issue"}
                                    placement={"top"}
                                >
                                    <Button onClick={() => setDuplicateIssueDialogIsOpen(true)}>
                                        <ContentCopy/>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    id={"issue-link-button"}
                                    title={linkCopied ? "Copied!" : "Get Issue Link"}
                                    placement={"top"}
                                    // open={linkCopied}
                                    onMouseLeave={handleCopyIssueLinkMouseLeave}
                                >
                                    <Button onClick={handleCopyIssueLink}>
                                        <LinkIcon/>
                                    </Button>
                                </Tooltip>
                                <Tooltip id={"delete-issue-button"} title={"Delete Issue"} placement={"top"}>
                                    <Button
                                        variant={props.isDeleting ? "contained" : "outlined"}
                                        onClick={props.toggleDeleteIssueDialog}
                                        size={"small"}
                                        sx={{
                                            color: "red",

                                        }}>
                                        {/*<DeleteIcon/>*/}
                                        <DeleteIcon/>
                                    </Button>
                                </Tooltip>

                            </ButtonGroup>
                        </Box>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        {
                            !props.isEditing ?
                                //Is not editing
                                <Box
                                    className={"issue-details"}
                                    sx={{width: "100%"}}
                                >
                                    <Box sx={{
                                        overflow: "auto"
                                    }}>
                                        <Typography
                                            variant={"h6"}
                                            sx={{
                                                padding: "8px 12px 23px 12px",
                                                lineHeight: "1.3",
                                                maxHeight: "12vh",
                                                overflowWrap: "break-word",
                                                // textDecoration: props.issue.state
                                            }}
                                        >
                                            {props.issue.issueName}</Typography>
                                    </Box>

                                    <Box style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: "8px 12px 85px 12px"
                                    }}
                                         // onClick={() => props.toggleEditing("description")}
                                    >
                                        {props.issue.issueDescription === "" ?
                                            <>
                                                <NotesIcon fontSize={"small"} sx={{color: "gray", paddingRight: "2px;"}}/>
                                                <Typography variant={"subtitle1"} color={"gray"}>
                                                    Description
                                                </Typography></>
                                            :
                                            <Typography sx={{
                                                whiteSpace: "pre-line",
                                                fontSize: "0.875rem",
                                                overflow: "auto",
                                                maxHeight: "20vh"
                                            }}>{props.issue.issueDescription}</Typography>}
                                    </Box>


                                </Box> :
                                //Is editing
                                <Box className={"issue-details editing"} component={"form"} onSubmit={handleSaveChanges}
                                sx={{paddingBottom: "20px;"}}>
                                    <TextField
                                        label={"Name"}
                                        value={props.newName}
                                        sx={{width: "100%", marginBottom: "5px;", paddingTop: "6px", paddingBottom: "6px"}}
                                        onChange={onChangeName}
                                        placeholder={"Enter a name for your issue"}
                                        autoFocus={(props.editFocus === "" || props.editFocus === "name")}
                                        InputProps={{
                                            sx: {
                                                "& .MuiInputBase-input": {
                                                    padding: "8px 12px 8px 12px"
                                                },
                                                fontSize: "1.25rem;"
                                            }
                                        }
                                        }
                                    />
                                    <TextField
                                        label={"Description"}
                                        // placeholder="MultiLine with rows: 2 and rowsMax: 4"
                                        multiline
                                        minRows={2}
                                        maxRows={12}
                                        fullWidth={true}
                                        autoFocus={props.editFocus === "description"}
                                        value={props.newDescription}
                                        sx={{
                                            paddingBottom: '5px',
                                            width: "100%",
                                            "& .MuiInputBase-root": {
                                                padding: "8px 12px 8px 12px"
                                            }
                                        }}
                                        InputProps={{
                                            sx:{
                                                fontSize:".875rem"
                                            }
                                        }}
                                        onChange={onChangeDescription}
                                    />
                                    <ButtonGroup
                                        sx={{
                                            height: "30px"
                                        }}
                                    >
                                        <Button //sx={{mt: 1}}
                                            variant={"contained"} onClick={() => {
                                            if (props.editSomethingChanged) {
                                                props.setCancelEditsDialogIsOpen(true)
                                            } else {
                                                props.toggleEditing()
                                            }

                                        }
                                        }>Cancel</Button>
                                        <Button //sx={{mt: 1}}
                                            variant={"contained"} type={"submit"} disabled={!props.newName}>Save
                                            Changes</Button>
                                    </ButtonGroup>
                                </Box>
                        }
                    </ErrorBoundary>

                </Grid>
            </Container>
            {/* DUPLICATE ISSUE CONFIRMATION */}
            <Dialog
                open={duplicateIssueDialogIsOpen}
                //onClose={} //Don't need this. necessary logic is executed when clicking "YES"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="md">
                <DialogTitle>Duplicate Issue</DialogTitle>
                <DialogContent>Are you sure you want to duplicate this issue?</DialogContent>
                <DialogActions className={"button-row-medium"}>
                    <Button onClick={() => {setDuplicateIssueDialogIsOpen(false)}}>Cancel</Button>
                    <Button onClick={duplicateIssue} >Duplicate Issue</Button>
                </DialogActions>

            </Dialog>
        </>


    )
}