import React, {useContext, useEffect, useState} from 'react'
import {Box, Button, Card, CardContent, CircularProgress, Container, Grid, Typography} from "@mui/material";
import IssueCard from "./Issue/IssueCard";
import IssueWindow from "./Issue/IssueWindow";
import {AppContext} from "../../index";
import useAuth from "../../hooks/useAuth";
import {instance} from "../../providers/AuthProvider";
import {useNavigate, useParams} from "react-router-dom";
import DashboardTableHeader from "./DashboardTableHeader";


export default function DashboardTableView(props) {

    let params = useParams();
    const navigate = useNavigate();

    const {auth} = useAuth();
    const {endpoints, priorityColors,priorityOptions,sortOptions} = useContext(AppContext);

    const [issueData, setIssueData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [stateToShow, setStateToShow] = useState("OPEN");

    //Issue Window
    const [currentIssueId, setCurrentIssueId] = useState("");
    const [issueWindowIsOpen, setIssueWindowIsOpen] = React.useState(false);
    const handleIssueWindowOpen = (issueId) => {
        navigate("/dashboard/" + issueId);
        setCurrentIssueId(issueId);
        setIssueWindowIsOpen(true);
    }

    const issueWindowClosed = () => {
        navigate("/dashboard");
        getIssues();
        setCurrentIssueId(null)
        setIssueWindowIsOpen(false);
    }

    const [initialMount, setInitialMount] = useState(false);
    const [searchParams, setSearchParams] = useState({
        priority: priorityOptions,
        sort: [sortOptions[0]],
        label: []
    });

    useEffect(() => {
        if (!initialMount){
            setInitialMount(true)
            return
        }
        // console.log(searchObject)
        getIssues()
    }, [searchParams]);


    function buildSearchObject() {
        var searchObject = {
            filters:[],
            sorts:[],
            page:null,
            size:null
        }
        //priority
        searchObject.filters.push({
            key:"priority",
            operator:"IN",
            field_type:"PRIORITY",
            values:searchParams.priority
            // value:searchParams.priority
        })

        //sort
        searchObject.sorts=[]
        //["Newest","Oldest","Most commented","Least commented","Recently updated","Least recently updated"]
        switch (searchParams.sort){
            case "Newest":
                searchObject.sorts.push({
                    key:"createdOn",
                    direction:"DESC"
                })
                break;
            case "Oldest":
                searchObject.sorts.push({
                    key:"createdOn",
                    direction:"ASC"
                })
                break;
            case "Most commented":
                searchObject.sorts.push({
                    key:"numberOfComments",
                    direction:"DESC"
                })
                break;
            case "Least commented":
                searchObject.sorts.push({
                    key:"numberOfComments",
                    direction:"ASC"
                })
                break;
            case "Recently updated":
                searchObject.sorts.push({
                    key:"lastUpdated",
                    direction:"ASC"
                })
                break;
            case "Least recently updated":
                searchObject.sorts.push({
                    key:"lastUpdated",
                    direction:"DESC"
                })
                break;
            default:
                console.error("Unknown sort value: " + searchParams.sort)
                break

        }
        return searchObject;
    }

    const getIssues = () => {
        // console.log("Getting issues for userId: \"" + auth.user.userId + "\"")
        // instance.get(`${endpoints.issues}?userId=${auth.user.userId}`)

        //There should always be at least 1 filter and 1 sort.
        // If there aren't, ignore as we don't have all required params yet
        // if (filters.length < 1 && sorts.length < 1){
        //     return
        // }

        var searchObject = buildSearchObject();

        const requestBody = {
            filters: [
                {
                    key: "userId",
                    operator: "EQUAL",
                    field_type: "LONG",
                    value: auth.user.userId
                },
                ...searchObject.filters
            ],
            sorts: searchObject.sorts,
            page: searchObject.page,
            size: searchObject.size
        }
        instance.post(`${endpoints.search}?userId=${auth.user.userId}`, requestBody)
            .then(response => {
                setIssueData(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    //Init
    useEffect(() => {
        if (params.issueId) {
            setCurrentIssueId(params.issueId);
            setIssueWindowIsOpen(true);
        } else {
            // getIssues();
            setCurrentIssueId(null)
            setIssueWindowIsOpen(false);
        }
    }, []);

    //Search
    const [searchQuery, setSearchQuery] = useState("");
    const [openCounts, setOpenCounts] = useState(0);
    const [closedCounts, setClosedCounts] = useState(0);

    //Anytime issueData/searchQuery updated, reprocess issueData
    const [issueDataPostProcessing, setIssueDataPostProcessing] = useState(null);
    useEffect(() => {
        if (issueData === null){
            return
        }
        var tempIssueData = {...issueData}
        var tempOpenCounts = 0
        var tempClosedCounts = 0

        var tempIssues = tempIssueData.issues
            .filter(issue => searchQuery === "" ||
                issue.issueName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                issue.issueDescription.toLowerCase().includes(searchQuery.toLowerCase())
            ) //Searchbox filter
            .filter(issue => { //Update open/closed counts.
                if (issue.state === "OPEN"){
                    tempOpenCounts++
                }
                if (issue.state === "CLOSED"){
                    tempClosedCounts++
                }
                return true;
            })
            .filter(issue => issue.state === stateToShow)


        setOpenCounts(tempOpenCounts)
        setClosedCounts(tempClosedCounts)

        tempIssueData.issues = tempIssues
        setIssueDataPostProcessing(tempIssueData)

    }, [issueData,searchQuery,stateToShow]);


    //Creates issue cards
    const generateIssueCards = (issuesToMakeCards) => {
        //Sort issues by columnIndex and index

        return (<>
                {
                    issuesToMakeCards
                        // .sort((a, b) => (a.issueIndex > b.issueIndex ? 1 : -1)) //No longer needed, not using dnd or index
                        .map(issue => {
                            return (
                                <IssueCard
                                    key={issue.issueId}
                                    issue={issue}
                                    color={priorityColors[issue.priority]}
                                    handleIssueWindowOpen={handleIssueWindowOpen}
                                    getIssues={getIssues}
                                    // onClick={(event) => {
                                    //     // event.currentTarget == event.target && handleIssueWindowOpen(issue.issueId)
                                    //     // if (event.target.id !== "issue-card-menu-btn") {
                                    //     //     handleIssueWindowOpen(issue.issueId)
                                    //     // }
                                    //     handleIssueWindowOpen(issue.issueId)
                                    // }}
                                />)
                        })}
            </>)
    }

    function handleNewIssue(newIssue = {}) {

        if (newIssue.type !== undefined) {
            newIssue = {}
        }

        newIssue = {
            ...newIssue,
            userId: auth.user.userId
        }

        instance.post(`${endpoints.issues}/new`, newIssue)
            .then(response => {
                // console.log(response.data);
                handleIssueWindowOpen(response.data.issueId)
            })
            .catch(error => {
                console.error(`Error: ${error}`)
            })


    }

    return (
        <>
            <Container className={"dashboard"} maxWidth={"lg"}>
                {/*<MsgBanner bannerText={"Example banner content here"}/>*/}
                <Box className={"dashboard-content"}>
                    <Box sx={{
                        display: "flex", justifyContent: "space-between",
                        margin: "10px 20px 10px 0"
                    }}>
                        <Typography variant={"h5"}>{auth.user.displayName}'s Issues</Typography>
                        <Button variant={"contained"} className={"new-issue-button"} onClick={() => {
                            handleNewIssue({
                                columnIndex: 1,
                            })
                        }
                        }>
                            New Issue</Button>
                    </Box>

                    <Box className={"issue-grid"} paddingTop={"15px"}>
                        <Grid container direction={"row"} sx={{margin: "0 -10px 0 -10px"}}>
                            {/*<DragDropContext onDragEnd={onDragEnd}> //not using dnd at this time*/}

                                <Card
                                    sx={{
                                        width: "100%",
                                        // boxShadow: 2,
                                        // borderRadius:"10px;"
                                        border: "1px solid",
                                        borderColor: "rgba(1,1,1,0.37)"
                                    }}
                                >
                                    <DashboardTableHeader
                                        issueData={issueDataPostProcessing}
                                        stateToShow={stateToShow}
                                        setStateToShow={setStateToShow}
                                        getIssues={getIssues}
                                        searchParams={searchParams}
                                        setSearchParams={setSearchParams}
                                        searchQuery={searchQuery}
                                        setSearchQuery={setSearchQuery}
                                        openCounts={openCounts}
                                        closedCounts={closedCounts}
                                    />
                                    {/*Max height*/}
                                    <CardContent sx={{
                                        padding: 0, "&:last-child": {
                                            paddingBottom: 0
                                        }
                                    }}>
                                        {/*<Droppable droppableId={1}>*/}
                                        {/*    {(provided, snapshot) => (*/}
                                        {/*        generateIssueCards(issueData.issues, stateToShow, provided, snapshot))}*/}
                                        {/*</Droppable>*/}
                                        {issueDataPostProcessing ? generateIssueCards(issueDataPostProcessing?.issues) : <CircularProgress sx={{margin: "15px"}}/>}
                                    </CardContent>
                                </Card>
                                {/*{generateColumns(columnsData)}*/}

                            {/*</DragDropContext>*/}
                        </Grid>
                    </Box>
                </Box>
                {issueWindowIsOpen &&
                    <IssueWindow
                        issueId={currentIssueId}
                        issueWindowIsOpen={issueWindowIsOpen}
                        issueWindowClosed={issueWindowClosed}
                        // forceDashboardUpdate={forceDashboardUpdate}
                    />}
            </Container>
        </>

    )
}