import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import {AppContext} from "../../../index";
import useAuth from "../../../hooks/useAuth";
import {instance} from "../../../providers/AuthProvider";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from "@mui/material/Menu";

export default function Comment(props) {

    const {auth} = useAuth()
    const {endpoints} = useContext(AppContext);
    const comment = props.comment;
    const theme = useTheme();
    const [commentMenuOpen, setCommentMenuOpen] = useState(false);
    const [commentMenuAnchor, setCommentMenuAnchor] = useState(null);

    const [isEditingComment, setIsEditingComment] = useState(false);

    const [editingSubmitBtnDisabled, setEditingSubmitBtnDisabled] = useState(true);
    // const [currentCommentEdit, setCurrentCommentEdit] = useState((localStorage.getItem("debuggy-comment-in-progress_" + props.comment.id) === null || localStorage.getItem("debuggy-comment-in-progress_" + props.comment.id) === "") ? "" : localStorage.getItem("debuggy-comment-in-progress_" + props.comment.id));
    const [currentCommentEdit, setCurrentCommentEdit] = useState("");

    function handleCommentEditChange(e) {
        // console.log(e.target.value)
        localStorage.setItem("debuggy-comment-in-progress_" + props.comment.id, e.target.value)
        setCurrentCommentEdit(e.target.value)
        if (e.target.value !== comment.commentText) {
            setEditingSubmitBtnDisabled(false)
        } else {
            setEditingSubmitBtnDisabled(true)
        }
        // setEditingSubmitBtnDisabled(!e.target.value)
    }

    function cancelEditComment() {
        setIsEditingComment(false)
        resetCurrentComment()
    }

    function updateEditComment(e) {

        if (currentCommentEdit === "") {
            return;
        }
        var updatedComment = {
            id: comment.id, commentText: currentCommentEdit,
        }

        instance.put(`${endpoints.issues}/${props.issue.issueId}/comments`, updatedComment)
            .then(response => {
                // Returns back List of comments including new one
                props.setComments(response.data);
                setIsEditingComment(false)
                resetCurrentComment();
            })
            .catch(err => {
                console.log(err)
            })

    }

    function resetCurrentComment() {
        setCurrentCommentEdit("");
        localStorage.removeItem("debuggy-comment-in-progress_" + props.comment.id)
    }

    function openCommentMenu(event) {
        setCommentMenuOpen(true)
        setCommentMenuAnchor(event.currentTarget)
        // console.log("opening menu")
    }

    function closeCommentMenu() {
        setCommentMenuOpen(false)
        setCommentMenuAnchor(null)
        // console.log("closing menu");
    }

    function editComment() {
        closeCommentMenu()
        setCurrentCommentEdit(comment.commentText)
        setIsEditingComment(true)
    }

    function deleteComment() {
        closeCommentMenu()
        console.log("deleting comment");
        //TODO: Dialog: Are you sure you want to delete this comment?
        instance.delete(`${endpoints.issues}/${props.issue.issueId}/comments/${comment.id}`)
            .then(response => {
                //TODO: Update parent issue
                props.setComments(response.data)
            })
            .catch(error => {
                console.error(`Error: ${error}`)
            })

    }

    return (<Box sx={{paddingRight: "6px;", paddingLeft: "0px;"}}>
        <Card
            elevation={0}
            sx={{borderBottom: "1px solid #BBBBBB", borderRadius: "0"}}

        >
            <CardHeader
                avatar={<Avatar sx={{
                    bgcolor: theme.palette.primary.main, width: "30px", height: "30px"
                }}
                aria-label="comment">
                    {comment.commentedBy.displayName.charAt(0)}
                </Avatar>}
                title={comment.commentedBy.displayName}
                subheader={new Date(comment.createdOn).toLocaleString()}
                action={
                    !comment.isDeleted &&
                    <>
                        <IconButton aria-label={"settings"} onClick={openCommentMenu}>
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            anchorEl={commentMenuAnchor}
                            open={commentMenuOpen}
                            onClose={closeCommentMenu}
                        >
                            <MenuItem onClick={editComment}>Edit</MenuItem>
                            <MenuItem onClick={deleteComment}>Delete</MenuItem>
                        </Menu>
                    </>
                }
                sx={{
                    paddingLeft: "0px;", paddingBottom: "8px"
                }}
            />

            <CardContent
                sx={{
                    paddingLeft: "0px;", paddingTop: "6px", paddingBottom: "10px !important",
                }}>

                {comment.isDeleted ? <Typography variant={"body2"}>
                        [deleted]
                    </Typography>

                    :

                    isEditingComment ? //IS EDITING COMMENT
                        <TextField
                            label={"Comment"}
                            // placeholder="MultiLine with rows: 2 and rowsMax: 4"
                            multiline
                            minRows={1}
                            maxRows={4}
                            fullWidth={true}
                            onChange={e => handleCommentEditChange(e)}
                            value={currentCommentEdit}
                            InputProps={{
                                endAdornment: <Box
                                    sx={{marginLeft: "6px", display: "flex", flexDirection: "column"}}>
                                    <Button variant="contained" disabled={editingSubmitBtnDisabled}
                                            onClick={updateEditComment}
                                            sx={{minWidth: "88px", marginBottom: "6px"}}
                                    >Submit</Button>
                                    <Button variant="contained" disabled={false}
                                            onClick={cancelEditComment} sx={{minWidth: "88px"}}
                                    >Cancel</Button>
                                </Box>, sx: {
                                    "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root": {
                                        padding: "4px 8px 4px 8px"
                                    }, fontSize: ".875rem;",

                                }
                            }}/> : //IS NOT EDITING
                        <Typography variant={"body2"} sx={{wordBreak: "break-word"}}>
                            {comment.commentText}
                        </Typography>

                }
            </CardContent>
        </Card>
        {/*<Box key={comment.key}*/}
        {/*     paddingBottom={"10px"}*/}
        {/*     marginBottom={"10px;"}*/}
        {/*     borderBottom={"2px solid grey"}>*/}
        {/*    <Box*/}
        {/*        display={"flex"}*/}
        {/*        columnGap={"20px;"}>*/}
        {/*        <p><b>Author:</b> {comment.commentedBy.username}</p>*/}
        {/*        <p><b>Posted:</b> {new Date(comment.createdOn).toLocaleString()}</p>*/}
        {/*    </Box>*/}
        {/*    <p>{comment.commentText}</p>*/}
        {/*</Box>*/}
    </Box>)
}