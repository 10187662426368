import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {DevSupport} from "@react-buddy/ide-toolbox";


import {ComponentPreviews, useInitial} from "./dev";
import {AuthProvider} from "./providers/AuthProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';


export const AppContext = createContext({})
const baseUrl = process.env.NODE_ENV === "production" ? "https://debuggy-api.up.railway.app" : "http://localhost:8080";
console.log(baseUrl)
console.log(process.env.NODE_ENV)
const apiVersionUrl = "api"
const appContext = {
    endpoints: {
        websiteUrl: process.env.NODE_ENV === "production" ? "https://debuggy.app" : "http://localhost:3000",
        baseUrl: `${baseUrl}`,
        issues: `${baseUrl}/${apiVersionUrl}/issues`,
        search: `${baseUrl}/${apiVersionUrl}/issues/search`,
        users: `${baseUrl}/${apiVersionUrl}/users`,
        login: `${baseUrl}/login`,
        active: `${baseUrl}/login/active`,
        refresh: `${baseUrl}/login/refresh`,
        usernameCheck: `${baseUrl}/login/check`,
        register: `${baseUrl}/login/register`,
        confirm: `${baseUrl}/login/register/confirm`,
        resend: `${baseUrl}/login/register/resend`,
        reset: `${baseUrl}/${apiVersionUrl}/users/util/reset`
    },
    priorityColors: {
        MINOR: "",//"#89E9B7", //"#3F64BF", purple looks cool, but it looks to close to the blue NORMAL
        NORMAL: "#2F3E9E",
        MAJOR: "#FBC60C",
        CRITICAL: "#F63319",
    },
    priorityOptions: ["NORMAL","MAJOR","CRITICAL"],
    sortOptions: ["Newest","Oldest","Most commented","Least commented","Recently updated","Least recently updated"]
}

//Dark theme, too many issues getting stuff looking right
// const theme = createTheme({
//     palette: {
//         type: 'dark',
//         primary: {
//             main: '#34b27b',
//         },
//         secondary: {
//             main: '#f50057',
//         },
//         background: {
//             default: '#1c1c1c',
//             paper: '#232323',
//         },
//         text: {
//             primary: '#ededed',
//         },
//
//     },
// });
const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#2f3e9e',
        },
        secondary: {
            main: '#f44335',
        },
        background: {
            default: '#f5f5f6',
            paper: '#f3f3f3',
        },
    },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppContext.Provider value={appContext}>
                <AuthProvider>
                    <DevSupport
                        ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
                    >
                        <ThemeProvider theme={theme}>
                            <CssBaseline/>
                            <Routes>
                                <Route path="/*" element={<App/>}/>
                            </Routes>
                        </ThemeProvider>
                    </DevSupport>
                </AuthProvider>
            </AppContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
