import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useAuth from "../../hooks/useAuth";
import {Link as RouterLink} from "react-router-dom"


export default function HeaderBasicMenu(props) {

    const {auth} = useAuth();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ ...props.menuButtonStyles, color: "white"}}
            >
                <AccountBoxIcon/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem {...{key: "My Account", to: "/my-account", component: RouterLink}} onClick={() => {handleClose();}}>My Account</MenuItem>
                <MenuItem onClick={() => {handleClose();auth.logOut()}}>Logout</MenuItem>

            </Menu>
        </>
    );
}