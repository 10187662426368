import {Box, Button, Grid, InputAdornment, Link, Paper, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import useAuth from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import {AppContext} from "../../index";
import zxcvbn from "zxcvbn";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {instance} from "../../providers/AuthProvider";
import ErrorMsgElement from "./ErrorMsgElement";


function PersonIcon() {
    return null;
}

export default function Register(props) {

    const { endpoints } = useContext(AppContext);
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const loginRedirectUrl = "/dashboard"
    const from = location.state?.from?.pathname || loginRedirectUrl

    const [displayName, setDisplayName] = useState("");
    // const [displayNameError, setDisplayNameError] = useState(false);
    // const [displayNameErrMsg, setDisplayNameErrMsg] = useState("");

    // useEffect(() => {
    //     setDisplayNameError(false)
    //     setPasswordErrMsg("")
    // }, [displayName]);



    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrMsg, setEmailErrMsg] = useState("");

    useEffect(() => {

        if(email !== confirmEmail && email !== "" && confirmEmail !== ""){
            // setTimeout(() => {
            setEmailError(true)
            setEmailErrMsg("Emails do not match")
            // },500)
        } else {
            setEmailError(false)
            setEmailErrMsg("\u00a0\u00a0")
        }

    }, [email, confirmEmail]);


    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState("");

    useEffect(() => {
        if (password === "" && confirmPassword === ""){
            setPasswordError(false)
            setPasswordErrMsg("\u00a0\u00a0")
        } else if (password !== "" && confirmPassword === ""){
            if (password.length < 8) {
                setPasswordError(true)
                setPasswordErrMsg("Passwords must be at least 8 characters")
            } else {
                const complexity = zxcvbn(password)
                if (complexity.score < 2){
                    setPasswordError(true)
                    setPasswordErrMsg("Please use numbers, capitals and symbols to make your password more complex")
                } else {
                    setPasswordError(false)
                    setPasswordErrMsg("\u00a0\u00a0")
                }
            }
        } else if (password !== confirmPassword){
            setPasswordError(true)
            setPasswordErrMsg("Passwords do not match")
        } else {
            const complexity = zxcvbn(password)
            if (complexity.score < 2){
                setPasswordError(true)
                setPasswordErrMsg("Please use numbers, capitals and symbols to make your password more complex")
            } else {
                setPasswordError(false)
                setPasswordErrMsg("\u00a0\u00a0")
            }
        }
    }, [password, confirmPassword]);



    //General Error Message
    const [errMsg, setErrMsg] = useState("");

    //If already logged in?
    useEffect(() => {
        if (auth.isLoggedIn()){
            navigate(loginRedirectUrl, { replace: true });
        }
    }, []);
    //Remove error message when they start interacting
    useEffect(() => {
        setErrMsg("")
    }, [email,password]);

    //Check if username(email) is already in use
    useEffect(() => {

        const checkUsername = async (email) => {
            //Wait X ms before checking
            const params = new URLSearchParams();
            params.append('username', email);
            params.append('password',password);
            try {
                // const response = await instance.post(`${endpoints.login}`, params)
            } catch {

            }
        }

        checkUsername(email);

    }, [email]);


    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        // if (displayName === ""){
        //     setDisplayNameError(true)
        //     setDisplayNameErrMsg("Please enter a display name.")
        //     return;
        // }
        if (emailError || passwordError){
            return;
        }
        const apiIsUsernameTaken = async (email) => {
            const params = new URLSearchParams();
            params.append('username', email);
            try {
                const response = await instance.post(`${endpoints.usernameCheck}`,params)
                return response?.data;
            } catch (err) {
                console.error(`Error: ${err}`)
                if (!err?.response){
                    return "No Server Response";
                } else if (err.response?.status === 400){
                    return "Invalid parameter, must use \"username\""
                } else if (err.response?.status === 401){
                    return "Unauthorized"
                } else if (err.response?.status === 404){
                    return "Invalid endpoint"
                } else {
                    return "Unknown Error: " + err.response?.status
                }
            }
        }
        console.log("Attempting to register user " + email)
        //Check if username is taken
        const isUsernameTaken = await apiIsUsernameTaken(email)
        if (isUsernameTaken !== false){
            console.log("Username is already taken")
            setEmailError(true)
            setEmailErrMsg("Username is already taken")
            return;
        }
        console.log("Username is unique, continuing with registration")
        //Username is unique, continue with registration
        const json = {
            username: email,
            password: password,
            displayName: displayName,
        }
        try {
            const response = await instance.post(`${endpoints.register}`,json)
            //Success!
            //console.log(response?.data)

            //Redirect to Email Verification

            //Automatically log in
            navigate("/verify/success")
            // await auth.logIn("/dashboard", email, password)

        } catch (err) {
            console.error(`Error: ${err}`)
            if (!err?.response){
                return "No Server Response";
            } else if (err.response?.status === 400){
                return "Missing Username or Password"
            } else if (err.response?.status === 401){
                return "Unauthorized"
            } else {
                return "Login Failed"
            }
            // errRef.current.focus();
        }

    }


    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }
    function toggleShowConfirmPassword() {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        <Grid item>
                            <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                Register
                            </Typography>
                            <ErrorMsgElement errMsg={errMsg}/>
                        </Grid>
                        <Grid item>
                            <Box
                                component={"form"}
                                onSubmit={handleRegisterSubmit}
                            >
                                <Grid container direction="column" spacing={2}>

                                    {/*<Grid item>{errorMsgElement(displayNameErrMsg)}</Grid>*/}
                                    <Grid item>
                                        <TextField
                                            // error={displayNameError}
                                            label={"Display Name"}
                                            InputLabelProps={{ required: true }}
                                            type="text"
                                            fullWidth
                                            name="displayName"
                                            variant="outlined"
                                            value={displayName}
                                            onChange={(e) => setDisplayName(e.target.value)}
                                            // ref={{userRef}}
                                            autoComplete={"off"}
                                            margin={"dense"}
                                            required
                                            autoFocus
                                            sx={{paddingTop: "0px", marginTop: "0px"}}
                                            inputProps={{
                                                maxLength: 40
                                            }}
                                        />
                                    </Grid>
                                    {/*<Grid sx={{padding: "5px"}}/>*/}

                                    <Grid item>
                                        <ErrorMsgElement errMsg={emailErrMsg}/>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={emailError}
                                            label={"Email"}
                                            InputLabelProps={{ required: false }}
                                            type="email"
                                            fullWidth
                                            name="username"
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            // ref={{userRef}}
                                            autoComplete={"off"}
                                            margin={"dense"}
                                            required
                                            // autoFocus
                                            sx={{paddingTop: "0px", marginTop: "0px"}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={emailError}
                                            label={"Confirm Email"}
                                            InputLabelProps={{ required: false }}
                                            type="email"
                                            fullWidth
                                            name="confirm-username"
                                            variant="outlined"
                                            value={confirmEmail}
                                            onChange={(e) => setConfirmEmail(e.target.value)}
                                            // ref={{userRef}}
                                            autoComplete={"off"}
                                            // helperText={emailErrMsg}
                                            required
                                            // autoFocus
                                        />
                                    </Grid>
                                    {/*<Grid sx={{padding: "5px"}}/>*/}
                                    <Grid item><ErrorMsgElement errMsg={passwordErrMsg}/></Grid>
                                    <Grid item>
                                        <TextField
                                            error={passwordError}
                                            label={"Password"}
                                            InputLabelProps={{ required: false }}
                                            type= {showPassword ? "text" : "password"}
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            sx={{paddingTop: "0px", marginTop: "0px"}}
                                            InputProps={{
                                                // disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end"
                                                    onClick={toggleShowPassword}
                                                    sx ={{
                                                        cursor: "pointer"
                                                    }}>
                                                        {showPassword ? <Visibility/> : <VisibilityOff/> }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={passwordError}
                                            label={"Confirm Password"}
                                            InputLabelProps={{ required: false }}
                                            type={showConfirmPassword ? "text" : "password"}
                                            fullWidth
                                            name="confirm-password"
                                            variant="outlined"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            InputProps={{
                                                // disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end"
                                                                    onClick={toggleShowConfirmPassword}
                                                                    sx ={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/> }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <PasswordStrengthBar password={password}/>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography component={"p"} sx={{paddingTop: "15px;"}}>
                                Already Have an Account?
                            </Typography>
                            <Link href={"/login"}>Sign In Here</Link>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}