import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    TextField,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../index";
import useAuth from "../../../hooks/useAuth";
import {instance} from "../../../providers/AuthProvider";
import Comment from "./Comment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ExpandLess} from "@mui/icons-material";


export default function IssueComments(props){

    const {endpoints} = useContext(AppContext);
    const {auth} = useAuth();


    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
    const [comments, setComments] = useState([]);
    const [currentComment, setCurrentComment] = useState(
        (localStorage.getItem("debuggy-comment-in-progress") === null ||
            localStorage.getItem("debuggy-comment-in-progress") === "") ?
            "" : localStorage.getItem("debuggy-comment-in-progress")
    );

    useEffect(() => {
        // return () => {

            instance.get(`${endpoints.issues}/${props.issue.issueId}/comments`)
                .then(response => {
                    setComments(response.data);
                })
                .catch(error => console.error(`Error: ${error}`));
        // };
    }, []);

    //Make sure submit button is not disabled if there's text in the comment box
    useEffect(() => {
        if (currentComment && currentComment !== ""){
            setSubmitBtnDisabled(false)
        } else {
            setSubmitBtnDisabled(true)
        }
    }, [currentComment]);




    function handleChange(e){
        // console.log(e.target.value)
        localStorage.setItem("debuggy-comment-in-progress",e.target.value)
        setCurrentComment(e.target.value)
        setSubmitBtnDisabled(!e.target.value)
    }


    function submitNewComment() {

        if (!currentComment || currentComment === "") {
            return;
        }
        var newComment = {
            commentedByUserId: auth.user.userId,
            commentText: currentComment,
        }

        instance.post(`${endpoints.issues}/${props.issue.issueId}/comments`,newComment)
            .then(response => {
                // console.log("success")
                // Returns back List of comments including new one
                setComments(response.data);
                resetCurrentComment();
                //Refectch "lastupdated"
                props.fetchIssue() //refetch issue so it updates "last modified"
            })
            .catch(err => {
                console.log(err)
            })

    }

    function resetCurrentComment(){
        setCurrentComment("");
        localStorage.setItem("debuggy-comment-in-progress","")
    }

    const [accordionOpen, setAccordionOpen] = useState(true);

    function handleCommentsAccordionChange() {
        setAccordionOpen(!accordionOpen)
    }

    return (
        <Container >
            <Accordion expanded={accordionOpen} elevation={0} disableGutters onChange={handleCommentsAccordionChange}>
                <AccordionSummary
                    sx={{padding: "0", flexDirection:"row-reverse",
                        borderTop:"1px solid #BBBBBB",
                        // marginTop:"20px;",
                        borderRadius:"0",
                        // maxHeight: "28vh;",
                        fontSize:"",
                        "&:hover":{
                            backgroundColor: "rgba(187,187,187,0.12)"
                        }
                    }}
                    expandIcon={<ExpandLess/>}
                >
                    <Typography><strong>Comments</strong></Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding: "0"}}>
                    <Box className={"comment-chain"}
                         sx={{
                             maxHeight: "28vh;",
                             minHeight: "20vh;",
                             overflow: "auto",
                         }}>
                        {comments.map(comment => <Comment issue={props.issue} comment={comment} setComments={setComments} key={comment.id}/>)}
                    </Box>
                    <Box maxWidth={"sm"} paddingTop={"20px;"}>
                        <TextField
                            label={"Comment"}
                            // placeholder="MultiLine with rows: 2 and rowsMax: 4"
                            multiline
                            minRows={1}
                            maxRows={4}
                            fullWidth={true}
                            onChange={e => handleChange(e)}
                            value={currentComment}
                            InputProps={{
                                endAdornment: <Box sx={{display:"flex",alignItems:"flex-end"}}>
                                    <Button variant="contained" disabled={submitBtnDisabled}
                                            onClick={submitNewComment}
                                            sx={{
                                                marginLeft: "6px",
                                            }}
                                    >Submit</Button>
                                </Box>,
                                sx: {
                                    "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root": {
                                        padding: "4px 8px 4px 8px"
                                    },
                                    fontSize: ".875rem;",

                                }
                            }}/>
                    </Box>
                </AccordionDetails>
        <Box paddingTop={"20px;"}>


        </Box>
            </Accordion>
        </Container>

    )
}