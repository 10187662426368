import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import IssueSidebar from "./IssueSidebar.js";
import IssueComments from "./IssueComments.js";
import IssueDetails from "./IssueDetails";
import {ErrorBoundary} from "../../../ErrorBoundary";
import {AppContext} from "../../../index";

import useAuth from "../../../hooks/useAuth";
import {instance} from "../../../providers/AuthProvider";
import {Close} from "@mui/icons-material";


export default function IssueWindow(props) {



    const {endpoints} = useContext(AppContext);
    const {auth} = useAuth();

    const [issueWindowDialogIsOpen, setIssueWindowDialogIsOpen] = useState(true);
    const [attemptingToCloseIssueWindow, setAttemptingToCloseIssueWindow] = useState(false);

    useEffect(() => {
        if (!issueWindowDialogIsOpen){
            props.issueWindowClosed()
        }
    }, [issueWindowDialogIsOpen]);


    const [issue, setIssue] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [isCreatingNewIssue, setIsCreatingNewIssue] = useState(false);

    const fetchIssue = () => {
        instance.get(`${endpoints.issues}/${props.issueId}`)
            .then(response => {
                setIssue(response.data);
                // console.log(response.data)
                setIssueWindowDialogIsOpen(true);
                if (response.data.issueName === "") {
                    setIsEditing(true)
                    setIsCreatingNewIssue(true)
                }
            })
            .catch(error => console.error(`Error: ${error}`))
        // console.log("useEffect in IssueWindow")

    }

    //Initial load
    useEffect(() => {
        if (props.issueId && !issue){
            //Initial load
            // console.log("useEffect in IssueWindow - Initial Load")
            fetchIssue()
        } else if (!props.issueId){
            // console.log("issueId is invalid: " + props.issueId);
        }

    }, []);

    const updateIssue = async (updateIssue) => {
        // console.log("updateIssue")
        instance.post(`${endpoints.issues}`,updateIssue, {
            headers: auth.getAccessTokenHeader()
        })
            .then(response => {
                // console.log("setIssue")
                // console.log(response.data);
                setIssue(response.data);

            })
            .catch(error => console.error(`Error: ${error}`))
    }

    const deleteIssue = async () => {
        //At this point, confirmation was received from user
        //Make delete call to database
        console.log("Attempting to delete issueId: \"" + issue.issueId + "\"")
        instance.delete(`${endpoints.issues}/${issue.issueId}`)
            .then(() => {
                console.log("Issue deleted")
                setIssueWindowDialogIsOpen(false)
                props.issueWindowClosed()
                // attemptToCloseIssueWindowDialog(); //Dont attempt, it'll cause a loop.
            })
            .catch(error => console.error(`Error: ${error}`))

    }

    const attemptToCloseIssueWindowDialog = () => {
        setAttemptingToCloseIssueWindow(true);
    }
    useEffect(() => {
        function deleteStaleCommentData() {
            localStorage.removeItem("debuggy-comment-in-progress")

            Object.entries(localStorage).map(
                x => x[0] //# get keys
            ).filter(
                x => x.includes("debuggy-comment-in-progress")
            ).map(
                x => localStorage.removeItem(x))
        }

        if (attemptingToCloseIssueWindow){
            //Are we still editing?
            if (isEditing){
                //If so, show additional prompt "Are you sure? Changes/Issue will be discarded"
                toggleCancelEditsDialog(true)
            } else {
                //Else close
                setIssueWindowDialogIsOpen(false)
                deleteStaleCommentData()
                props.issueWindowClosed()
            }

        }
    }, [attemptingToCloseIssueWindow]);



    /* Editing */
    const [editFocus, setEditFocus] = useState("name");
    const [editSomethingChanged, setEditSomethingChanged] = useState(false);
    const [newName, setNewName] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [cancelEditsDialogIsOpen, setCancelEditsDialogIsOpen] = useState(false);
    const toggleCancelEditsDialog = () => {setCancelEditsDialogIsOpen(prevState => !prevState)}
    const toggleEditing = (negateCreatingNewIssue = false,focus = "name") => {
        if (isCreatingNewIssue && !negateCreatingNewIssue){
            setAttemptingToCloseIssueWindow(true)
            return
        }
        setIsEditing(prevState => {
            setNewName(issue.issueName)
            setNewDescription(issue.issueDescription)
            setEditFocus(focus)
            return !prevState
        })
        setEditSomethingChanged(false)
    }

    /* Delete issue */
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    function toggleDeleteIssueDialog(){
        setDeleteDialogIsOpen(prevState => !prevState);
    }

    function handleDeleteIssue() {
        setDeleteDialogIsOpen(false)
        deleteIssue();
    }

    /* Delete new issue */
    const [deleteNewIssueDialogIsOpen, setDeleteNewIssueDialogIsOpen] = useState(false);
    function toggleDeleteNewIssueDialog(){
        setDeleteNewIssueDialogIsOpen(prevState => !prevState);
    }

    function handleDeleteNewIssue() {
        setDeleteNewIssueDialogIsOpen(false)
        deleteIssue();
    }

    /* Map escape key */
    // TODO: This doesn't work. mui Dialog already has an event listener for the escape key
    //       It doesn't work well with nested dialogs either. not sure how to handle this.
    // useEffect(() => {
    //     const close = (e) => {
    //         if(e.key === 'Escape'){
    //             console.log("escape pressed")
    //
    //             if (cancelEditsDialogIsOpen) { //Same as pressing cancel
    //                 setCancelEditsDialogIsOpen(false)
    //             } else if (deleteDialogIsOpen){ //Same as pressing cancel
    //                 setDeleteDialogIsOpen(false)
    //             } else if (issueWindowDialogIsOpen){ //Same as clicking outside of window to go back
    //                 attemptToCloseIssueWindowDialog()
    //
    //             }
    //         }
    //     }
    //     window.addEventListener('keydown', close)
    //     return () => window.removeEventListener('keydown', close)
    // },[])

    return (
        <>
            <Dialog
                open={issueWindowDialogIsOpen}
                onClose={attemptToCloseIssueWindowDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                fullWidth
                maxWidth="md"

            >
                <Container className={"issue"}
                           sx={{
                               display:"flex",
                               direction:"row",
                               minHeight:"80vh;"
                }}>
                    <Grid container direction="row">
                        <Grid container item xs={8} direction={"column"} sx={{display:"flex",justifyContent:"space-between"}}>
                            {/*Wait for issue to be retrieved before rendering child components*/}
                            <ErrorBoundary>
                                {issue && <IssueDetails
                                    issue={issue}
                                    setIssue={setIssue}
                                    updateIssue={updateIssue}
                                    deleteIssue={deleteIssue}
                                    isEditing={isEditing}
                                    setIsEditing={setIsEditing}
                                    setIsCreatingNewIssue={setIsCreatingNewIssue}
                                    editFocus={editFocus}
                                    setEditSomethingChanged={setEditSomethingChanged}
                                    editSomethingChanged={editSomethingChanged}
                                    newName={newName}
                                    setNewName={setNewName}
                                    newDescription={newDescription}
                                    setNewDescription={setNewDescription}
                                    toggleEditing={toggleEditing}
                                    setCancelEditsDialogIsOpen={setCancelEditsDialogIsOpen}
                                    isDeleting={isDeleting}
                                    toggleDeleteIssueDialog={toggleDeleteIssueDialog}
                                />}
                            </ErrorBoundary>
                            <ErrorBoundary>{issue && issue.issueName !== "" &&
                                <IssueComments
                                    issue={issue}
                                    setIssue={setIssue}
                                    fetchIssue={fetchIssue}
                                />}
                            </ErrorBoundary>
                        </Grid>
                        <Grid container item xs={4} direction={"column"}>
                            <Box sx={{display:"flex",flexDirection:"row-reverse"}}>
                                <Close
                                    sx={{
                                        marginBottom: "10px;",
                                        color: "gray",
                                        fontSize: "2rem",
                                        "&:hover": {
                                            color:"black"
                                        }
                                    }}
                                    onClick={()=>{
                                        setAttemptingToCloseIssueWindow(true);
                                    }}
                                />
                            </Box>
                            <ErrorBoundary>{issue &&
                                <IssueSidebar issue={issue}
                                              updateIssue={updateIssue}/>}
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            {/* CANCEL EDIT CONFIRMATION */}
            {/*Also deletes new issue if editing new issue*/}
            <Dialog
                open={cancelEditsDialogIsOpen}
                //onClose={} //Don't need this. necessary logic is executed when clicking "YES"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="md">
                <DialogTitle>Discard changes?</DialogTitle>
                <DialogContent>
                    {isCreatingNewIssue ? "This issue will be deleted" : "The changes you made will not be saved."}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCancelEditsDialogIsOpen(false);
                        if (attemptingToCloseIssueWindow){
                            setAttemptingToCloseIssueWindow(false)
                        }
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        setCancelEditsDialogIsOpen(false);
                        toggleEditing()
                        if (attemptingToCloseIssueWindow || isCreatingNewIssue){
                            if (isCreatingNewIssue){
                                deleteIssue() //Function closes window after completion
                            }

                        }
                    }}>Discard Changes</Button>
                </DialogActions>

            </Dialog>

            {/* DELETE CONFIRMATION */}
            <Dialog
                open={deleteDialogIsOpen}
                //onClose={} //Don't need this. necessary logic is executed when clicking "YES"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="md">
                <DialogTitle>Are you sure you want to delete this issue?</DialogTitle>
                <DialogContent>All content will be lost</DialogContent>
                <DialogActions className={"button-row-medium"}>
                    <Button onClick={toggleDeleteIssueDialog}>Cancel</Button>
                    <Button onClick={handleDeleteIssue} >Delete Issue</Button>
                </DialogActions>

            </Dialog>

        </>

    )
}