import {Box, Button, Grid, Paper, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {instance} from "../../providers/AuthProvider";
import {AppContext} from "../../index";

export default function ForgotPassword(props) {

    const {endpoints} = useContext(AppContext)

    const [emailAddress, setEmailAddress] = useState("");
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false);

    async function handleForgotPasswordSubmit() {
        instance.post(`${endpoints.reset}?email=${emailAddress}&token=null&password=null`)
        setForgotPasswordSubmitted(true)
    }

    useEffect(() => {
        if (emailAddress !== ""){
            setSubmitButtonDisabled(false)
        } else {
            setSubmitButtonDisabled(true)
        }
    }, [emailAddress]);


    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center", minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        <>
                            <Grid item>
                                <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                    Reset your password
                                </Typography>
                            </Grid>

                            {!forgotPasswordSubmitted ?
                                <Grid item>
                                    <Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>
                                        Please enter the email address tied to your account and we will send you a link
                                        to reset your password.
                                    </Typography>
                                    <TextField
                                        // label={"Change display name"}
                                        fullWidth={true}
                                        onChange={e => setEmailAddress(e.target.value)}
                                        value={emailAddress}
                                        InputProps={{
                                            maxLength: 40,
                                            endAdornment: <Box sx={{display: "flex", alignItems: "flex-end"}}>
                                                <Button variant="contained" disabled={submitButtonDisabled}
                                                        onClick={handleForgotPasswordSubmit}
                                                        sx={{
                                                            marginLeft: "6px",
                                                        }}
                                                >
                                                    Reset
                                                </Button>
                                            </Box>,
                                            sx: {
                                                "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root": {
                                                    padding: "4px 8px 4px 8px"
                                                },
                                                fontSize: ".875rem;",

                                            }
                                        }}
                                        sx={{paddingBottom: "40px"}}
                                    />
                                </Grid>
                                :
                                <Grid item>
                                    <Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>
                                        Thank you! Please check your email for a reset link.
                                    </Typography>
                                </Grid>}
                        </>


                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}