import './App.css';
import React from "react";
import DebuggyHeader from "./components/header/DebuggyHeader";
import {Route, Routes} from "react-router-dom";

import Login from "./components/login/Login";
import {Box} from "@mui/material";
import RequireAuth from "./components/RequireAuth";
import MyAccount from "./components/content/MyAccount";
import Register from "./components/register/Register";
import VerifyEmail from "./components/register/VerifyEmail";
import Confirm from "./components/register/Confirm";
import ResetPassword from "./components/login/ResetPassword";
import ForgotPassword from "./components/login/ForgotPassword";
import Home from "./components/home/Home.page";
import DashboardTableView from "./components/content/DashboardTableView";

function App(props) {

    return (

        <Box className={"app"}
             sx={{
                 // height: "100%;",
                 width: "100%;",
                 contain: "layout;",
                 overflowY: "auto;"
             }}>
            <DebuggyHeader />
            <Box className={"app-content"}
                 component={"section"}
                 sx={{marginTop: "80px", width:"100%"}}>
                <Routes>
                    <Route path={"/"} element={<Home/>}/>

                    {/* Unprotected Routes */}
                    <Route path="login" element={<Login/>}/>
                    <Route path="register" element={<Register/>}/>
                    <Route path="verify/:success" element={<VerifyEmail/>}/>
                    <Route path="verify" element={<VerifyEmail/>}/>
                    <Route path="confirm" element={<Confirm/>}/>
                    <Route path="forgot" element={<ForgotPassword/>}/>
                    <Route path="reset" element={<ResetPassword/>}/>

                    {/* Protected Routes */}
                    <Route element={<RequireAuth/>}>
                        <Route path="dashboard" element={<DashboardTableView/>}/>
                        <Route path="dashboard/:issueId" element={<DashboardTableView/>}/>
                        <Route path="my-account" element={<MyAccount/>}/>
                    </Route>

                </Routes>
            </Box>
        </Box>
    );

}

export default App;
