import {Grid, MenuItem, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../index";
import useAuth from "../../../hooks/useAuth";
import {instance} from "../../../providers/AuthProvider";


export default function IssueSidebar(props) {

    const {endpoints} = useContext(AppContext);
    const {auth} = useAuth();



    // const [issue, setIssue] = useState(props.issue);

    const createDate = new Date(props.issue.createdOn);

    //Store input value lists
    const [inputListValues, setInputListValues] = useState({});

    //Initial dropdown values
    useEffect(() => {
        // console.log("issueSidebar - useEffet - init")
        //STATE
        instance.get(`${endpoints.issues}/${props.issue.issueId}/state`, {
            headers: auth.getAccessTokenHeader()
        })
            .then(response => {
                // console.log(response.data);
                setInputListValues(prevState => {
                    return {
                        ...prevState,
                        state : response.data
                    }
                })
            })
            .catch(error => console.error(`Error: ${error}`))

        //PRIORITY
        instance.get(`${endpoints.issues}/${props.issue.issueId}/priority`, {
            headers: auth.getAccessTokenHeader()
        })
            .then(response => {
                setInputListValues(prevState => {
                    return {
                        ...prevState,
                        priority : response.data
                    }
                })
            })
            .catch(error => console.error(`Error: ${error}`))
    }, []);


    // useEffect(() => {
    //     setIssue(issue)
    // }, [props.issue]);

    //Select dropdowns
    const handleDropdownChange = (e) =>{
        //Client -> Server: Request Update issue
        //Server: Update issue
        //Server -> Client: Success -> updated issue, failure -> failure
        //Client: Success -> setIssue(updatedIssue), failure -> display failure

        //Create new issue object with updated properties
        // console.log("handleDropdownChange trigger");
        if (!props.issue.hasOwnProperty(e.target.name)){
            throw new Error("issue doesn't contain property '" + e.target.name + "'. Did you name the input element properly?")
        }
        var updateIssue = {
            ...props.issue,
            [e.target.name] : inputListValues[e.target.name][e.target.value]
        }

        if ([e.target.name] === "state"){
            updateIssue.columnIndex = e.target.value
        }
        //Pass to parent function to attempt db update
        // console.log("handleDropdownChange")
        props.updateIssue(updateIssue);
    }


    return (
        <>
            <Grid className={"issue-sidebar"} container direction={"column"} sx={{marginTop: "8px;"}}>
                <Grid container direction={"column"} className={"issue-sidebar__row"}>
                    <Grid><Typography className={"label"}>Created By:</Typography></Grid>
                    <Grid><Typography className={"value"}>{props.issue.createdBy.displayName}</Typography></Grid>
                </Grid>
                <hr/>
                <Grid container direction={"column"} className={"issue-sidebar__row"}>
                    <Grid><Typography className={"label"}>Created on:</Typography></Grid>
                    <Grid><Typography className={"value"}>{createDate.toLocaleString()}</Typography></Grid>
                </Grid>
                <hr/>
                <Grid container direction={"column"} className={"issue-sidebar__row"}>
                    <Grid><Typography className={"label"}>Last Updated:</Typography></Grid>
                    <Grid><Typography className={"value"}>{props.issue.lastUpdated ? new Date(props.issue.lastUpdated).toLocaleString() : "Never"}</Typography></Grid>
                </Grid>




                {/*PRIORITY*/}
                <Grid container direction={"row"} className={"issue-sidebar__row"} sx={{marginTop: "18px"}}>
                    {inputListValues.priority && (
                        <TextField
                            name={"priority"}
                            label={"Priority"}
                            sx={{width: "100%"}}
                            select
                            size={"small"}
                            defaultValue={1}
                            value={inputListValues.priority.indexOf(props.issue.priority)}
                            onChange={handleDropdownChange}>
                            {inputListValues.priority.map((priority, index) => <MenuItem value={index}
                                                                                         key={index}>{priority}</MenuItem>)}
                        </TextField>)}
                </Grid>

                {/*STATE*/}
                <Grid container direction={"row"} className={"issue-sidebar__row"} sx={{marginTop: "18px"}}>
                    {inputListValues.state && (
                        <TextField
                            select
                            label={"State"}
                            name={"state"}
                            defaultValue={1}
                            size={"small"}
                            sx={{width: "100%"}}
                            value={inputListValues.state.indexOf(props.issue.state)}
                            onChange={handleDropdownChange}>
                            {inputListValues.state.map((state, index) => <MenuItem value={index}
                                                                                   key={index}>{state}</MenuItem>)}
                        </TextField>)}
                </Grid>


            </Grid>
        </>
    )
}