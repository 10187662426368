import {Grid, Link, Paper, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../index";
import {instance} from "../../providers/AuthProvider";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function Confirm(props){

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [confirmSuccess, setConfirmSuccess] = useState(false);

    const [errorMsg, setErrorMsg] = useState("");
    const [confirmFailure, setConfirmFailure] = useState(false);

    const [initComplete, setInitComplete] = useState(false);

    const {endpoints} = useContext(AppContext);

    // console.log("confirm page")

    useEffect(() => {

        const confirmToken = async () => {

            let token = searchParams.get("token")

            const response = await instance.get(`${endpoints.confirm}?token=${token}`)
            const msg = response?.data;

            if (msg.toLocaleLowerCase().includes("confirm") && !confirmFailure) {
                setConfirmSuccess(true)
            } else if (!confirmSuccess) {
                setErrorMsg(msg);
                setConfirmFailure(true)
            }

            setInitComplete(true)
        }

        confirmToken()

    }, []);

    const resendConfirmationEmail = () => {
        const resendEmail = async () => {
            try {

                let token = searchParams.get("token")

                const response = await instance.get(`${endpoints.resend}?token=${token}`)
                if (response?.data === "success"){
                    navigate("/verify")
                } else {
                    setErrorMsg("Unable to resend confirmation email. Please contact site administrators for assistance.")
                }
            } catch {
                console.log("Could not successfully resend confirmation email!")
                setErrorMsg("Unable to resend confirmation email. Please contact site administrators for assistance.")
            }

        }

        resendEmail();

    }

    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        <Grid item>
                            <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                {initComplete && confirmSuccess && "Thank you"}
                                {initComplete && confirmFailure && "Unable to confirm"}
                            </Typography>
                        </Grid>

                        <Grid item>

                                {initComplete && confirmSuccess &&
                                    (<>
                                        <Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>
                                            You have registered your account!
                                        </Typography>
                                        <Link href={"/login"} sx={{cursor: "pointer"}}
                                        >Login to get started</Link>
                                    </>)}
                                {initComplete && confirmFailure &&
                                    (<>
                                        <Typography sx={{marginBottom: "10px"}}>{errorMsg}</Typography>
                                        <Link onClick={resendConfirmationEmail} sx={{cursor: "pointer"}}
                                        >Click here to resend the confirmation link</Link>
                                    </>)}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}