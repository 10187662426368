import React from 'react'
import {AppBar, Box, Button, Container, Toolbar, Typography, useTheme} from "@mui/material";
import {Link, Link as RouterLink} from "react-router-dom"
import useAuth from "../../hooks/useAuth";
import HeaderBasicMenu from "./HeaderBasicMenu";
import BugReportIcon from '@mui/icons-material/BugReport';

const headersData = [

];

export default function DebuggyHeader() {

    const {auth} = useAuth();

    // console.log("isLoggedIn: " + auth.isLoggedIn())

    const displayDesktop = () => {
        return (
            <Toolbar sx={{ width: "100%"}}>
                <Container maxWidth={"lg"} sx ={{
                    maxWidth:"1200px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent:"center",
                }}>
                    <Typography
                        variant={"h6"}
                        component={"h1"}
                        sx={{
                            fontFamily: "Work Sans, sans-serif",
                            fontWeight: 600,
                            textAlign: "left",
                        }}
                    >
                        <Button
                            {...{
                                key: "Home",
                                color: "inherit",
                                to: auth.isLoggedIn ? "/dashboard" : "/",
                                component: RouterLink,
                            }}
                        style={{
                            textTransform: "none",
                            fontSize: "1.25rem",
                            lineHeight: "1.6",
                            letterSpacing: "0.0075em"
                        }}>
                            <BugReportIcon style={{
                                verticalAlign: "middle",
                                lineHeight: "1px",
                                marginBottom: "3px",
                                marginRight: "2px",
                                transform: "rotate(-11deg) scale(1.23)",
                                color: "inherit"
                            }}/>
                            Debuggy
                        </Button>
                    </Typography>
                    <Box style={{display:"flex",justifyContent:"middle"}}>
                        {getMenuButtons()}
                    </Box>
                </Container>
            </Toolbar>
        );
    }

    const menuButtonStyles = {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
        color: "white",
    }

    const getMenuButtons = () => {

        return (
            <>
                {/* OTHER BUTTONS (none atm) */}
                {headersData.map(({ label, href }) => {
                    return (
                        <Button
                            {...{
                                key: label,
                                color: "inherit",
                                to: href,
                                component: RouterLink,

                            }}
                            sx={menuButtonStyles}
                        >
                            {label}
                        </Button>
                    );
                })}

                {/* DASHBOARD BUTTON */}
                {auth.isLoggedIn() &&
                    <Button
                        {...{
                            key: "Dashboard",
                            color: "inherit",
                            to: "/dashboard",
                            component: RouterLink,

                        }}
                        sx={menuButtonStyles}
                    >
                        Dashboard
                    </Button>}

                {/* LOG IN BUTTON */}
                {!auth.isLoggedIn() &&
                    <Button
                        {...{
                            key: "Login",
                            color: "inherit",
                            to: "/login",
                            component: RouterLink,

                        }}
                        sx={menuButtonStyles}
                    >
                        Login
                    </Button>}

                {/* REGISTER BUTTON */}
                {!auth.isLoggedIn() &&
                    <Button
                        {...{
                            key: "Register",
                            color: "inherit",
                            to: "/register",
                            component: RouterLink,

                        }}
                        sx={menuButtonStyles}
                    >
                        Register
                    </Button>}

                {/* LOGGED IN DROPDOWN */}
                {auth.isLoggedIn() && <HeaderBasicMenu menuButtonStyles={menuButtonStyles}/>}
            </>
            )


    };


    return (
        <header className={"header"}>
            <AppBar component={"div"} >
                {displayDesktop()}
            </AppBar>
        </header>
    )
}

