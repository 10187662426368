import React, {useEffect, useState} from 'react'
import {Box, Button, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";


export default function Login (props) {

    const loginRedirectUrl = "/dashboard"

    const { auth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || loginRedirectUrl
    // const userRef = useRef();
    // const errRef = useRef();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState(
        from === "/" ? "" : "Please log in to view this content"
    );

    //If already logged in?
    useEffect(() => {
        if (auth.isLoggedIn()){
            navigate(loginRedirectUrl, { replace: true });
        }
    }, []);

    useEffect(() => {
        setErrMsg("")
    }, [email,password]);


    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        const results = await auth.logIn(from, email, password);
        // setEmail('');
        // setPassword('');
        setErrMsg(results);
    }

    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px"}}
                    >
                        <Grid item>
                            <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                Sign in
                            </Typography>
                            <Typography component={"p"} aria-live={"assertive"} //ref={errRef}
                                sx={{visibility: errMsg ? "visible" : "hidden",
                                    color: "red", paddingBottom: "10px"}}
                            >{errMsg}</Typography>
                        </Grid>
                        <Grid item>
                            <Box
                                component={"form"}
                                onSubmit={handleLoginSubmit}
                            >
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <TextField
                                            label={"Email"}
                                            InputLabelProps={{ required: false }}
                                            type="email"
                                            fullWidth
                                            name="username"
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            // ref={{userRef}}
                                            autoComplete={"off"}
                                            required
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label={"Password"}
                                            InputLabelProps={{ required: false }}
                                            type="password"
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Link href={"/forgot"} variant="body2">
                                Forgot Password?
                            </Link>
                            <Typography component={"p"} sx={{paddingTop: "15px;"}}>
                                Need an Account?
                            </Typography>
                            <Link href={"/register"}>Sign Up</Link>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}