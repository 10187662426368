import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../index";
import useAuth from "../../hooks/useAuth";
import {Check, Visibility, VisibilityOff} from "@mui/icons-material";
import {instance} from "../../providers/AuthProvider";
import PasswordStrengthBar from "react-password-strength-bar";
import ErrorMsgElement from "../register/ErrorMsgElement";
import zxcvbn from "zxcvbn";


export default function MyAccount(props){

    const { endpoints } = useContext(AppContext);
    const { auth } = useAuth();

    const [newDisplayName, setNewDisplayName] = useState(auth.user.displayName)
    const [updateNameSubmitBtnDisabled, setUpdateNameSubmitBtnDisabled] = useState(true);
    const [nameUpdated, setNameUpdated] = useState(false);
    useEffect(() => {
        setNameUpdated(false)
        if (newDisplayName !== auth.user.displayName){
            setUpdateNameSubmitBtnDisabled(false)
        } else {
            setUpdateNameSubmitBtnDisabled(true)
        }
    }, [newDisplayName]);

    function handleDisplayNameSubmit() {
        if (nameUpdated){
            return
        }
        console.log("display name submitted")
        var updateUser = {
            userId: auth.user.userId,
            displayName: newDisplayName,
        }
        instance.put(`${endpoints.users}`, updateUser)
            .then(response => {
                console.log("successfully changed name");
                auth.updateUser(response.data);
                // auth.setUser(response.data)
                setNewDisplayName(response.data.displayName)
                // setUpdateNameSubmitBtnDisabled(true)
                setNameUpdated(true)
            })
            .catch(error => {
                console.error(`Error updating display name: ${error}`)
            })
    }

    const [currentPassword, setCurrentPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState("");

    const [passwordSubmitDisabled, setPasswordSubmitDisabled] = useState(true);


    useEffect(() => {

        if (password !== "" || confirmPassword !== "" || currentPassword !== ""){
            setPasswordUpdated(false)
        }

        if (password === "" && confirmPassword === ""){
            setPasswordError(false)
            setPasswordErrMsg("\u00a0\u00a0")
            setPasswordSubmitDisabled(true)
        } else if (password !== "" && confirmPassword === ""){
            setPasswordSubmitDisabled(true)
            if (password.length < 8) {
                setPasswordError(true)
                setPasswordErrMsg("Passwords must be at least 8 characters")
                setPasswordSubmitDisabled(true)
            } else {
                const complexity = zxcvbn(password)
                if (complexity.score < 2){
                    setPasswordError(true)
                    setPasswordErrMsg("Passwords must be more complex")
                    setPasswordSubmitDisabled(true)
                }
            }
        } else if (password !== confirmPassword){
            setPasswordError(true)
            setPasswordErrMsg("Passwords do not match")
            setPasswordSubmitDisabled(true)
        } else {
            const complexity = zxcvbn(password)
            if (complexity.score < 2){
                setPasswordError(true)
                setPasswordErrMsg("Passwords must be more complex")
                setPasswordSubmitDisabled(true)
            } else {
                setPasswordError(false)
                setPasswordErrMsg("\u00a0\u00a0")
                setPasswordSubmitDisabled(false)
            }
        }
    }, [currentPassword, password, confirmPassword]);

    const [passwordUpdated, setPasswordUpdated] = useState(false);

    const handleUpdatePasswordSubmit = async (e) => {
        e.preventDefault();
        if (passwordUpdated){
            return
        }
        var updateUser = {
            userId: auth.user.userId,
            currentPassword: currentPassword,
            newPassword: password,
        }
        instance.put(`${endpoints.users}`, updateUser)
            .then(response => {
                auth.setUser(response.data)
                setPassword("")
                setCurrentPassword("")
                setConfirmPassword("")
                setPasswordUpdated(true)
            })
            .catch(error => {
                console.error(`Error updating password: ${error}`)
                if (error?.response?.data === "Current password does not match"){
                    setPasswordErrMsg("Current password does not match")
                    setPasswordError(true)
                }
                setPasswordSubmitDisabled(true)
            })
    }

    function toggleShowCurrentPassword() {
        setShowCurrentPassword(!showCurrentPassword);
    }
    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }
    function toggleShowConfirmPassword() {
        setShowConfirmPassword(!showConfirmPassword);
    }


    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="my-account-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        <Grid item>
                            <Typography variant="h5" sx={{paddingBottom: "40px"}}>
                                My Account
                            </Typography>
                            <Typography sx={{paddingBottom: "20px"}}>
                                Display Name
                            </Typography>
                            <TextField
                                // label={"Change display name"}
                                fullWidth={true}
                                onChange={e => setNewDisplayName(e.target.value)}
                                value={newDisplayName}
                                InputProps={{
                                    maxLength: 40,
                                    endAdornment: <Box sx={{display: "flex", alignItems: "flex-end"}}>
                                        <Button variant="contained" disabled={updateNameSubmitBtnDisabled}
                                                onClick={handleDisplayNameSubmit}
                                                sx={{
                                                    marginLeft: "6px",
                                                    backgroundColor: nameUpdated ? "green" : "",
                                                    "&:hover" : {
                                                        backgroundColor: nameUpdated ? "green" : ""
                                                    }
                                                }}
                                        >
                                            {nameUpdated ? <Check sx={{color: nameUpdated ? "white" : ""}}/> : "Update"}
                                        </Button>
                                    </Box>,
                                    sx: {
                                        "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root": {
                                            padding: "4px 8px 4px 8px"
                                        },
                                        fontSize: ".875rem;",

                                    }
                                }}
                                sx={{paddingBottom:"40px"}}
                            />
                            {/*{errorMsgElement(errMsg)}*/}

                            {/*<Typography sx={{paddingBottom: "20px"}}>*/}
                            {/*    User Bio*/}
                            {/*</Typography>*/}
                            {/*<TextField*/}
                            {/*    // label={"New Name"}*/}
                            {/*    fullWidth={true}*/}
                            {/*    onChange={e => setNewDisplayName(e.target.value)}*/}
                            {/*    value={newDisplayName}*/}
                            {/*    InputProps={{*/}
                            {/*        endAdornment: <Box sx={{display: "flex", alignItems: "flex-end"}}>*/}
                            {/*            <Button variant="contained" disabled={updateNameSubmitBtnDisabled}*/}
                            {/*                    onClick={handleDisplayNameSubmit}*/}
                            {/*                    sx={{*/}
                            {/*                        marginLeft: "6px",*/}
                            {/*                    }}*/}
                            {/*            >Update</Button>*/}
                            {/*        </Box>,*/}
                            {/*        sx: {*/}
                            {/*            "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root": {*/}
                            {/*                padding: "4px 8px 4px 8px"*/}
                            {/*            },*/}
                            {/*            fontSize: ".875rem;",*/}
                            {/*        }*/}
                            {/*    }}/>*/}
                        </Grid>
                        <Grid item>
                            <Typography sx={{paddingBottom: "5px"}}>
                                Password
                            </Typography>
                            <Box
                                component={"form"}
                                onSubmit={handleUpdatePasswordSubmit}
                            >
                                <Grid container direction="column" spacing={2}>

                                    <Grid item><ErrorMsgElement errMsg={passwordErrMsg}/></Grid>
                                    <Grid item>
                                        <TextField
                                            error={passwordError}
                                            label={"Current Password"}
                                            InputLabelProps={{ required: false }}
                                            type= {showCurrentPassword ? "text" : "password"}
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            required
                                            sx={{paddingTop: "0px", marginTop: "0px"}}
                                            InputProps={{
                                                // disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end"
                                                                    onClick={toggleShowCurrentPassword}
                                                                    sx ={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                        {showCurrentPassword ? <Visibility/> : <VisibilityOff/> }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={passwordError}
                                            label={"Password"}
                                            InputLabelProps={{ required: false }}
                                            type= {showPassword ? "text" : "password"}
                                            fullWidth
                                            name="password"
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            sx={{paddingTop: "0px", marginTop: "0px"}}
                                            InputProps={{
                                                // disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end"
                                                                    onClick={toggleShowPassword}
                                                                    sx ={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                        {showPassword ? <Visibility/> : <VisibilityOff/> }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            error={passwordError}
                                            label={"Confirm Password"}
                                            InputLabelProps={{ required: false }}
                                            type={showConfirmPassword ? "text" : "password"}
                                            fullWidth
                                            name="confirm-password"
                                            variant="outlined"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            InputProps={{
                                                // disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end"
                                                                    onClick={toggleShowConfirmPassword}
                                                                    sx ={{
                                                                        cursor: "pointer"
                                                                    }}>
                                                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/> }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <PasswordStrengthBar password={password}/>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            fullWidth
                                            disabled={passwordSubmitDisabled}
                                        >
                                             {passwordUpdated ? <Check sx={{color: passwordUpdated ? "white" : ""}}/> : "Update Password"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}