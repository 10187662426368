import {
    Button,
    CardContent,
    Checkbox,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {ArrowDropDown, BugReport, Check, Search} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../index";

export default function DashboardTableHeader(props) {
    var opacityLow = "0.7";
    var transitionDelay = "190ms"
    var openButtonOpacity = props.stateToShow === "OPEN" ? "1" : opacityLow;
    var closedButtonOpacity = props.stateToShow === "CLOSED" ? "1" : opacityLow;

    function titleCase(str) {
        return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    }

    const {priorityOptions, sortOptions} = useContext(AppContext);

    //<editor-fold desc="PRIORITY DROPDOWN">
    const [priorityFilterValues, setPriorityFilterValues] = useState(priorityOptions);
    const [priorityDropdownIsOpen, setPriorityDropdownIsOpen] = useState(false);
    const [priorityDropdownAnchor, setPriorityDropdownAnchor] = useState(null);
    //</editor-fold>

    //<editor-fold desc="SORT DROPDOWN">
    const [sortFilterValues, setSortFilterValues] = useState("Newest");
    const [sortDropdownIsOpen, setSortDropdownIsOpen] = useState(false);
    const [sortDropdownAnchor, setSortDropdownAnchor] = useState(null);
    const [sortFilterInitialLoad, setSortFilterInitialLoad] = useState(true);


    useEffect(() => {
        // if (sortFilterInitialLoad){
        //     setSortFilterInitialLoad(false)
        // } else {
            props.setSearchParams(prevState => {
                return {
                    ...prevState,
                    sort: sortFilterValues
                }
            })
        // }
    }, [sortFilterValues]);

    //</editor-fold>
    

    return <CardContent
        sx={{
            backgroundColor: "rgba(0,0,0,0.02)",
            display: "flex",
            justifyContent: "space-between",
            color: "black",

        }}
    >
        <Stack direction={"row"} sx={{}}>
            { props.issueData &&
            <>
                <Button
                sx={{
                    opacity: openButtonOpacity,
                    "&:hover": {opacity: 1, transitionDelay: transitionDelay, transition: "opacity " + transitionDelay}
                }}
                onClick={() => {
                    if (props.stateToShow === "CLOSED") {
                        props.setStateToShow("OPEN")
                    }
                }}
            >
                <BugReport sx={{color: "#1a7f37", marginBottom: "2px"}}/> {props.openCounts} Open
            </Button>
            <Button
                sx={{
                    opacity: closedButtonOpacity,
                    "&:hover": {opacity: 1, transitionDelay: transitionDelay, transition: "opacity " + transitionDelay}
                }}
                onClick={() => {
                    if (props.stateToShow === "OPEN") {
                        props.setStateToShow("CLOSED")
                    }
                }}
            >
                <Check/> {props.closedCounts} Closed
            </Button></>}
        </Stack>
        <Stack direction={"row"}>
            <TextField
                onChange={(e) => props.setSearchQuery(e.target.value)}
                placeholder={"Search..."}
                InputProps={{
                    startAdornment: (
                        <Search/>
                    )
                }}
                size={"small"}
            />
        </Stack>
        <Stack direction={"row"}>
            <Button
                onClick={(e) => {
                    setPriorityDropdownAnchor(e.currentTarget)
                    setPriorityDropdownIsOpen(prevState => !prevState)
                }}
            >
                Priority
                <ArrowDropDown/>
            </Button>
            <FormControl>
                <Select
                    labelId={"priority-multiple"}
                    multiple
                    value={priorityFilterValues}
                    onChange={(e) => {
                        if (e.target.value !== null && e.target.value.length > 0){
                            setPriorityFilterValues(e.target.value)
                        }
                    }}
                    // input={<OutlinedInput label="Priority" />}
                    // input={<Box><Typography>Priority </Typography><ArrowDropDown/></Box>}
                    sx={{display: "none"}}
                    open={priorityDropdownIsOpen}
                    onOpen={() => {
                        setPriorityDropdownIsOpen(true)
                    }}
                    onClose={() => {
                        setPriorityDropdownAnchor(null)
                        setPriorityDropdownIsOpen(false)
                        props.setSearchParams(prevState => {
                            return {
                                ...prevState,
                                priority: priorityFilterValues
                            }
                        })

                    }}
                    // input={
                    MenuProps={{
                        anchorReference: "anchorEl",
                        anchorEl: priorityDropdownAnchor,
                    }}

                >
                    {priorityOptions.map(name => (
                        <MenuItem
                            key={titleCase(name)}
                            value={name}
                        >
                            <Checkbox checked={priorityFilterValues.indexOf(name) > -1}/>
                            <ListItemText primary={name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/*<Button>*/}
            {/*    Label <ArrowDropDown/>*/}
            {/*</Button>*/}
            <Button
                onClick={(e) => {
                    setSortDropdownAnchor(e.currentTarget)
                    setSortDropdownIsOpen(prevState => !prevState)
                }}
            >
                Sort <ArrowDropDown/>
            </Button>
            <FormControl>
                <Select
                    labelId={"sort-single"}
                    value={sortFilterValues}
                    onChange={(e) => {
                        if (e.target.value !== null && e.target.value.length > 0){
                            setSortFilterValues(e.target.value)
                        }
                    }}
                    sx={{display: "none"}}
                    open={sortDropdownIsOpen}
                    onOpen={() => {
                        setSortDropdownIsOpen(true)
                    }}
                    onClose={() => {
                        setSortDropdownAnchor(null)
                        setSortDropdownIsOpen(false)
                    }}
                    // input={
                    MenuProps={{
                        anchorReference: "anchorEl",
                        anchorEl: sortDropdownAnchor,
                    }}

                >
                    {sortOptions.map(name => (
                        <MenuItem
                            key={titleCase(name)}
                            value={name}
                        >
                            <Checkbox checked={sortFilterValues.indexOf(name) > -1}/>
                            <ListItemText primary={name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
        {/*right stack with filter buttons*/}
    </CardContent>;
}
