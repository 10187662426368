import React, {useContext, useEffect, useRef, useState} from 'react'
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";
import {BugReport, Check, Comment} from "@mui/icons-material";
import {AppContext} from "../../../index";
import {useLocation, useNavigate} from "react-router-dom";
import {instance} from "../../../providers/AuthProvider";
import toTime from 'to-time'


export default function IssueCard(props) {

    const {endpoints, priorityColors} = useContext(AppContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [issueNameOverflowed, setIssueNameOverflowed] = useState(false)
    const issueNameRef = useRef();
    useEffect(() => {
        setIssueNameOverflowed(issueNameRef.current.scrollWidth > issueNameRef.current.clientWidth)
    }, []);

    const [issueCardMenuAnchor, setIssueCardMenuAnchor] = useState(null);
    const issueCardMenuOpen = Boolean(issueCardMenuAnchor)

    function openIssueCardMenu(event) {
        setIssueCardMenuAnchor(event.currentTarget)
        // console.log("opening menu")
    }

    function closeIssueCardMenu() {
        setIssueCardMenuAnchor(null)
        // console.log("closing menu");
    }

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
    function deleteIssue() {
        console.log("Attempting to delete issueId: \"" + props.issue.issueId + "\"")
        instance.delete(`${endpoints.issues}/${props.issue.issueId}`)
            .then(() => {
                console.log("Issue deleted")
                props.getIssues();
            })
            .catch(error => console.error(`Error: ${error}`))
    }

    // const [linkCopied, setLinkCopied] = useState(false);
    async function getIssueLink() {
        navigator.clipboard.writeText(`${endpoints.websiteUrl}${location.pathname}/${props.issue.issueId}`)
        // setLinkCopied(true)
        closeIssueCardMenu()
        // setLinkCopied(false)
    }

    const [duplicateIssueDialogIsOpen, setDuplicateIssueDialogIsOpen] = useState(false);
    function duplicateIssue() {
        instance.post(`${endpoints.issues}/${props.issue.issueId}/duplicate`)
            .then(response => {
                // console.log(response.data);
                props.getIssues();
            })
            .catch(error => {
                console.error(`Error: ${error}`)
            })
    }


    function generateStateIcon(state) {
        const sx = {
            margin:"2px"
        }
        if (state === "OPEN") {
            return <BugReport sx={{...sx,color:"#1a7f37"}}/>
        // } else if (state === "IN_PROGRESS") {
        //     return <Pending sx={{...sx,color:"#1a7f37"}}/>
        } else if (state === "CLOSED") {
            return <Check sx={{...sx,color:"#2F3E9E"}}/>
        }
    }


    var DateDiff = {

        inHours: function(d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();

            return Math.floor((t2-t1)/(3600*1000));
        },

        inDays: function(d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();

            return Math.floor((t2-t1)/(24*3600*1000));
        },

        inWeeks: function(d1, d2) {
            var t2 = d2.getTime();
            var t1 = d1.getTime();

            return parseInt((t2-t1)/(24*3600*1000*7));
        },

        inMonths: function(d1, d2) {
            var d1Y = d1.getFullYear();
            var d2Y = d2.getFullYear();
            var d1M = d1.getMonth();
            var d2M = d2.getMonth();

            return (d2M+12*d2Y)-(d1M+12*d1Y);
        },

        inYears: function(d1, d2) {
            return d2.getFullYear()-d1.getFullYear();
        }
    }

    function getCreatedDateString(d1,d2){
        //TODO: Implement later
        // - If less than an hour, display minutes
        // - if > 1hr and < day, display hours
        // - if > 1day display days
        // - If > 1month, display months
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} placement={"top-start"} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
            padding:0,
            marginLeft:40,
            border: "1px solid",
            borderColor: "rgba(1,1,1,0.27)",
            backgroundColor: "rgba(1,1,1,0.0)",
        },
    });
    const descriptionCharLength = 130;


    function generateChipSx(priority) {
        var sx={}
        if (priority === "NORMAL"){
            sx.color = "white";
            sx.backgroundColor = priorityColors[priority]
        } else if (priority === "MAJOR"){
            sx.color = "black";
            sx.backgroundColor = priorityColors[priority]
        } else if (priority === "CRITICAL"){
            sx.color = "white";
            sx.backgroundColor = priorityColors[priority]
        }
        return sx;
}

//console.log(props.issue)

    function getCreatedByText() {
        return (<Link href="#">{props.issue.createdBy.displayName}</Link>)
    }

    function getCreatedOnOrLastModifiedText() {

        //Has it ever been modified?
        var wasModified = props.issue.lastUpdated !== null
        var durationText = wasModified ? "Updated " : "Created "
        var dateToUse = wasModified ? props.issue.lastUpdated : props.issue.createdOn
        var dateDiffMs = Date.now()-new Date(dateToUse)
        var durationMs = toTime(dateDiffMs + "ms")


        function isItPlural(amount) {
            return amount !== 1 ? "s" : "";
        }

        if (durationMs.minutes() < 60){
            //MINUTES
            var minutes = Math.floor(durationMs.minutes())
            durationText += minutes + " minute" + isItPlural(minutes)

        } else if (durationMs.minutes() >= 60 && durationMs.hours() < 24){
            //HOURS
            var hours = Math.floor(durationMs.hours())
            durationText += hours + " hour" + isItPlural(hours)

        } else if (durationMs.hours() >= 24 && durationMs.days() < 7 ){
            //DAYS
            var days = Math.floor(durationMs.days())
            durationText += days + " day" + isItPlural(days)

        } else if (durationMs.days() >= 7 && durationMs.days() < 30){
            //WEEKS
            var weeks = Math.floor(durationMs.days() / 7)
            durationText += weeks + " week" + isItPlural(weeks)

        } else if (durationMs.days() >= 30 && durationMs.days() < 365){
            //MONTHS
            var months = Math.floor(durationMs.days() / 30)
            durationText += months + " month" + isItPlural(months)

        } else if (durationMs.days() > 365 ){
            //YEARS + MONTHS
            var days = Math.floor(durationMs.days())
            var years = Math.floor(durationMs.years())
            durationText += years + " year" + isItPlural(years)
            var remainderMonths = Math.floor(days % 365 / 30)
            durationText += remainderMonths > 0 ? ", " + toTime(remainderMonths + " months").humanize() : ""

        }

        return durationText + " ago";
    }

    return (
        <>
            <CustomWidthTooltip
                enterNextDelay={600}
                title={
                    <Card sx={{
                        border: "none",
                        margin: 0,
                    }}>
                        {/*<CardHeader>*/}
                        {/*    {generateStateIcon(props.issue.state)}*/}
                        {/*</CardHeader>*/}
                        <CardContent
                            sx={{paddingBottom: "10px;"}}
                        >

                            <Typography
                                color={"textPrimary"}
                                display="inline"
                                fontSize={"14px"}
                            >
                                {/*<Box component={"span"} fontWeight={"fontWeightMedium"}>*/}
                                <b>{props.issue.issueName}</b>
                                <Typography
                                    display="inline"
                                    color={"textPrimary"}
                                    fontSize={"12px"}
                                    sx={{color: "#808080"}}
                                >
                                    &nbsp;#{props.issue.issueId}
                                </Typography>
                                {/*</Box>*/}
                            </Typography>
                            <Box>
                                <Typography
                                    style={{}}
                                    color={"textPrimary"}
                                    gutterBottom
                                    // paragraph={true}
                                    noWrap
                                    fontSize={"12px"}
                                    sx={{color: "#808080"}}
                                >
                                    Last Modified: {props.issue.lastUpdated ? new Date((Date.parse(props.issue.lastUpdated))).toLocaleString() : "never"}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    style={{}}
                                    color={"textPrimary"}
                                    // gutterBottom
                                    // paragraph={true}
                                    fontSize={"12px"}
                                    sx={{color: "#808080",}}
                                >
                                    {props.issue.issueDescription.length <= descriptionCharLength ? props.issue.issueDescription : (props.issue.issueDescription.substring(0, descriptionCharLength) + "...")}

                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
                placement={"top"}
                enterDelay={500}
            >
            <Card
                sx={{
                    // minHeight: "110px",
                    // maxHeight: "120px",
                    // height: "110px",
                    // margin: "10px 6px 10px 6px",
                    // ':hover': {
                    //     boxShadow: 4, // theme.shadows[20]
                    // },
                    ':hover': {
                        background: "#e1e1e1", // theme.shadows[20]
                    },
                    background: "#F5F5F6",
                    borderRadius: "0px;"
                }}
                variant={"outlined"}
            >
                <CardContent
                    sx={{
                        // background: "#F5F5F6",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        height: "60px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                    onClick={() => props.handleIssueWindowOpen(props.issue.issueId)}
                >
                    <Stack direction={"row"}>
                        <Stack>
                            {generateStateIcon(props.issue.state)}
                        </Stack>


                            <Stack direction={"column"}
                                   sx={{
                                       marginTop: "4px",
                                       marginLeft: "5px",
                                   }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>

                                    <Typography
                                        ref={issueNameRef}

                                        color={"textPrimary"}
                                        sx={{
                                            textDecoration: props.issue.state === "CLOSED" ? "line-through" : "",
                                            // fontWeight: props.issue.state === "CLOSED" ? "" : "font-weight-medium",
                                        }}
                                        fontWeight={ props.issue.state === "CLOSED" ? "" : "fontWeightMedium" }
                                        // gutterBottom
                                        // paragraph={true}
                                        noWrap
                                        fontSize={"14px"}
                                    >
                                        <Box //fontWeight={"fontWeightMedium"}
                                        >
                                            {props.issue.issueName}
                                        </Box>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        style={{}}
                                        color={"textPrimary"}
                                        gutterBottom
                                        // paragraph={true}
                                        noWrap
                                        fontSize={"12px"}
                                        sx={{color: "#808080"}}
                                    >

                                        {getCreatedOnOrLastModifiedText()} by {getCreatedByText()}
                                    </Typography>
                                </Box>
                            </Stack>

                    </Stack>
                    <Stack direction={"row"} sx={{marginTop: "5px;"}}>
                        <Stack>
                            <Chip
                                label={props.issue.priority}
                                sx={{
                                    ...(generateChipSx(props.issue.priority)),
                                    marginRight: "15px;"
                                }}
                            />
                        </Stack>
                        <Stack direction={"row"}>
                            <Comment/>&nbsp;{props.issue.totalComments}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </CustomWidthTooltip>
            {/* DUPLICATE ISSUE CONFIRMATION */}
            <Dialog
                open={duplicateIssueDialogIsOpen}
                //onClose={} //Don't need this. necessary logic is executed when clicking "YES"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="md">
                <DialogTitle>Duplicate Issue</DialogTitle>
                <DialogContent>Are you sure you want to duplicate this issue?</DialogContent>
                <DialogActions className={"button-row-medium"}>
                    <Button onClick={() => {
                        closeIssueCardMenu()
                        setDuplicateIssueDialogIsOpen(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        closeIssueCardMenu()
                        setDuplicateIssueDialogIsOpen(false)
                        duplicateIssue()
                    }}>Duplicate Issue</Button>
                </DialogActions>
            </Dialog>
            {/* DELETE CONFIRMATION */}
            <Dialog
                open={deleteDialogIsOpen}
                //onClose={} //Don't need this. necessary logic is executed when clicking "YES"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                maxWidth="md">
                <DialogTitle>Are you sure you want to delete this issue?</DialogTitle>
                <DialogContent>All content will be lost</DialogContent>
                <DialogActions className={"button-row-medium"}>
                    <Button onClick={() => {
                        closeIssueCardMenu()
                        setDeleteDialogIsOpen(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        closeIssueCardMenu()
                        setDeleteDialogIsOpen(false)
                        deleteIssue()
                    }}>Delete Issue</Button>
                </DialogActions>

            </Dialog>
        </>
    )


}