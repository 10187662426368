import {Box, Button, Grid, InputAdornment, Link, Paper, TextField, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Check, Visibility, VisibilityOff} from "@mui/icons-material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AppContext} from "../../index";
import {instance} from "../../providers/AuthProvider";
import ErrorMsgElement from "../register/ErrorMsgElement";
import PasswordStrengthBar from "react-password-strength-bar";
import zxcvbn from "zxcvbn";


export default function ResetPassword(props){

    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const {endpoints} = useContext(AppContext)

    const [resetSuccess, setResetSuccess] = useState(false);
    const [resetFailure, setResetFailure] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");



    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrMsg, setPasswordErrMsg] = useState("");

    const [passwordSubmitDisabled, setPasswordSubmitDisabled] = useState(true);


    useEffect(() => {

        if (password === "" && confirmPassword === ""){
            setPasswordError(false)
            setPasswordErrMsg("\u00a0\u00a0")
            setPasswordSubmitDisabled(true)
        } else if (password !== "" && confirmPassword === ""){
            setPasswordSubmitDisabled(true)
            if (password.length < 8) {
                setPasswordError(true)
                setPasswordErrMsg("Passwords must be at least 8 characters")
                setPasswordSubmitDisabled(true)
            } else {
                const complexity = zxcvbn(password)
                if (complexity.score < 2){
                    setPasswordError(true)
                    setPasswordErrMsg("Passwords must be more complex")
                    setPasswordSubmitDisabled(true)
                }
            }
        } else if (password !== confirmPassword){
            setPasswordError(true)
            setPasswordErrMsg("Passwords do not match")
            setPasswordSubmitDisabled(true)
        } else {
            const complexity = zxcvbn(password)
            if (complexity.score < 2){
                setPasswordError(true)
                setPasswordErrMsg("Passwords must be more complex")
                setPasswordSubmitDisabled(true)
            } else {
                setPasswordError(false)
                setPasswordErrMsg("\u00a0\u00a0")
                setPasswordSubmitDisabled(false)
            }
        }
    }, [password, confirmPassword]);

    const handleResetPasswordSubmit = async (e) => {
        e.preventDefault();
        if (resetSuccess){
            return
        }

        const response = await instance.post(`${endpoints.reset}?email=null&token=${searchParams.get("token")}&password=${password}`)
        const msg = response?.data;

        if (msg.toLocaleLowerCase().includes("success") && !resetFailure) {
            setResetSuccess(true)
        } else if (!resetSuccess) {
            setErrorMsg(msg);
            setResetFailure(true)
        }

        setResetSuccess(true)
    }

    function toggleShowPassword() {
        setShowPassword(!showPassword);
    }
    function toggleShowConfirmPassword() {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        {(resetSuccess || resetFailure) ?
                            <Grid item>
                                <Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>
                                    You have reset your password!!
                                </Typography>
                                <Link href={"/login"} sx={{cursor: "pointer"}}
                                >Login to get started</Link>
                            </Grid> :
                            <Grid item>
                                <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                    Enter your new password here:
                                </Typography>
                                <Box
                                    component={"form"}
                                    onSubmit={handleResetPasswordSubmit}
                                >
                                    <Grid container direction="column" spacing={2}>

                                        <Grid item><ErrorMsgElement errMsg={passwordErrMsg}/></Grid>
                                        <Grid item>
                                            <TextField
                                                error={passwordError}
                                                label={"Password"}
                                                InputLabelProps={{required: false}}
                                                type={showPassword ? "text" : "password"}
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                                sx={{paddingTop: "0px", marginTop: "0px"}}
                                                InputProps={{
                                                    // disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end"
                                                                        onClick={toggleShowPassword}
                                                                        sx={{
                                                                            cursor: "pointer"
                                                                        }}>
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                error={passwordError}
                                                label={"Confirm Password"}
                                                InputLabelProps={{required: false}}
                                                type={showConfirmPassword ? "text" : "password"}
                                                fullWidth
                                                name="confirm-password"
                                                variant="outlined"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                                InputProps={{
                                                    // disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end"
                                                                        onClick={toggleShowConfirmPassword}
                                                                        sx={{
                                                                            cursor: "pointer"
                                                                        }}>
                                                            {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <PasswordStrengthBar password={password}/>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                fullWidth
                                                disabled={passwordSubmitDisabled}
                                            >
                                                Reset Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}

