import {Grid, Paper, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {AppContext} from "../../index";
import {useParams} from "react-router-dom";

export default function VerifyEmail(props){

    const params = useParams()

    const [emailSent, setEmailSent] = useState(false);

    const {endpoints} = useContext(AppContext);
    const resendConfirmationEmail = () => {
      try {
          //hit resend endpoint
          // setEmailSent(true);

      } catch {

      }

    }

    return (
        <Grid container spacing={0} justifyContent="center" justify="center" alignItems="center" direction="row">
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                    sx={{justifyContent: "center", minHeight: "90vh"}}
                >
                    <Paper
                        variant="elevation"
                        elevation={2}
                        className="login-background"
                        sx={{justifyContent: "center",minHeight: "30vh", padding: "50px", width: "500px"}}
                    >
                        <Grid item>
                            <Typography component="h1" variant="h5" sx={{paddingBottom: "20px"}}>
                                {params?.success ? "Thank you for registering your account!" : "Verify Your Email"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>
                                A verification link has been sent to your email address. Please visit the link in order to activate your account.
                            </Typography>
                            {/*<Typography component="p" variant="p" sx={{paddingBottom: "20px"}}>*/}
                            {/*    Need us to send another link? */}
                            {/*    <Link */}
                            {/*        sx={{cursor:"pointer"}}*/}
                            {/*        onClick={resendConfirmationEmail}*/}
                            {/*    >Please click here</Link>*/}
                            {/*</Typography>*/}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}