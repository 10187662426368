import {Box, Button, Container, Typography, useTheme} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import React, {useEffect, useState} from "react";
import {useWindowSize} from "../../hooks/useWindowSize";
import {Link as RouterLink} from "react-router-dom";

export default function Home (props) {

    const theme = useTheme();

    const generateBugs = () => {
        var array = []
        for (let i = 0; i < 10; i++) {
            array.push(i)
        }
        return array.map((i) => {
            return <Bug key={i}/>
        })
    }

    return (
        <>
            <Box style={{
                width: "100%",
                height: "90vh",
                // backgroundColor:"light gray"
                display: "flex",
                justifyContent: "center"
            }}>
                <Box className={"hero-container"}
                           sx={{
                               display: "flex", justifyContent: "center",
                               height: "50vh",
                               // width: "100vw",
                               flexDirection: "column",
                               maxWidth: "600px"
                           }}>
                    <Box className={"hero-logo-container"}>
                        <Typography variant={"h1"} sx={{color: theme.palette.primary.main, fontWeight: "bold"}}>
                            <BugReportIcon style={{
                                verticalAlign: "middle",
                                lineHeight: "1px",
                                marginBottom: "15px",
                                marginRight: "2px",
                                transform: "rotate(-11deg)",
                                fontSize: "9rem",
                                color: "inherit"
                            }}/>
                            Debuggy
                        </Typography>
                    </Box>
                    <Box className={"hero-button-container"}
                         sx={{display: "flex", flexDirection: "row-reverse", marginTop: "20px", marginRight: "20px"}}
                         to={"/register"}
                         component={RouterLink}
                    >
                        <Button variant={"contained"}>Get Started</Button>
                    </Box>
                </Box>
                {/*
            //Main title with icon and Debuggy
            //Shrinks depending on window width that bottoms out with min-size

            //Little bugs that maneuver around the screen
            //  - Edges
            //  - elliptical movement
            //  - Pulse slightly to help simulate movement (bezo curve?)
            //  - Random pauses?
            //  - Fade in and out?
            //  - Little dashed line trail behind them in style of pen & paper
            //
            //
        */}
            </Box>
            {/*<Box className={"bug-container"}>*/}
            {/*    {generateBugs()}*/}
            {/*</Box>*/}
        </>

    )
}

function Bug(props) {

    //How to get this working?
    //  - Anchor bug to end of a long div
    //  - put div fulcrum at other end
    //  - Rotate the arm, which moves the bug
    //  - Behind the bug&div is a circular div with dash colored border
    //  - Ontop of that is a semitransparent white image that reveals portions of the trial
    //  - It rotates in tandem with the bug to reveal the trail
    //  - while also fading it out after so many degrees of rotation

    const {width, height} = useWindowSize();

    const [posX, setPosX] = useState(10000);
    const [posY, setPosY] = useState(10000);

    useEffect(() => {
        setPosY(height / 2 + Math.random() * 400)
        setPosX(width / 2 + Math.random() * 400)
    }, []);


    return (
        <BugReportIcon sx={{
            position:"absolute",
            right:posX,
            top:posY,
        }}/>
    )
}